import { FormControlLabel, Checkbox, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { fetchUpdateActivityLunchBreak } from "../../fetch/fecthActivityLunchBreak";
import useWindowSize from "../../hooks/useWindowSize";

export default function IncludeLunchBreak({ hoursOfTask, lunch, data, handleRefreshData }) {
  const queryClient = useQueryClient();
  const windowSize = useWindowSize();

  var d = data;
  var isSync = d[d.length - 1].isSynced;

  const { mutate } = useMutation(fetchUpdateActivityLunchBreak, {
    onSuccess: () => {
      setTimeout(() => {
        handleRefreshData(); 
      });
    },
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries(["activitiesLunchBreak"]);
    },
  });

  const handleCheckChange = () => {
    mutate({
      ActivityLunchBreakId: lunch.activityLunchBreakId,
      EmployeeId: lunch.employeeId,
      Date: lunch.date,
      IsIncludeBreak: !lunch?.isIncludeBreak,
    });
  };

  return (
    <FormControlLabel
      sx={{ justifyContent: "flex-end", mx: 0 }}
      disabled={
        isSync
          ? true
          : lunch.isIncludeBreak
          ? false
          : hoursOfTask < 1
          ? true
          : false
      }
      control={
        <Checkbox
          sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
          checked={lunch.isIncludeBreak}
          onChange={handleCheckChange}
        />
      }
      label={
        <Typography
          sx={{
            fontSize:
              windowSize.width > 1100 ? 16 : windowSize.width > 720 ? 14 : 12,
            color: isSync
              ? "gray"
              : lunch.isIncludeBreak
              ? "black"
              : hoursOfTask < 1
              ? "gray"
              : "black",
          }}
        >
          Include break (1 hr)
        </Typography>
      }
    />
  );
}
