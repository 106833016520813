import React from "react";

import { Button, useTheme } from "@mui/material";

import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { getServerTokenWithAzureAD } from "../../fetch/fetchAuthentication";
import toast from "react-hot-toast";

export const SignInButton = ({ customVariant, customBGColor, customBGColorHover, setIdxLogin }) => {
  const { login } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleLoginClick = async () => {
    try {
      var msResult = await login();
      if (msResult) {
        setTimeout(async () => {
          //Handle: Hash value cannot be processed because it is empty issue from MSAL.JS
          //Call new authentication endpoint with AzureAD Bearer
          var mcResult = await getServerTokenWithAzureAD(msResult.accessToken, msResult.account.username);
          if (mcResult) {
            toast.success("Logged in successfully");
            setIdxLogin((state)  => state + 1);
            navigate("/activity");
          } 
          else {
            toast.error("Logged in failed");
            navigate("/login");
          }
        });
      }
      else {
        toast.error("Logged in failed");
      }
    }
    catch {
      toast.error("Something failed while loggin. Please contact Mycos developement team.");
    }
  };

  return (
    <Button
      variant={customVariant ? customVariant : ""}
      onClick={handleLoginClick}
      sx={[
        {
          mx: "auto",
          px: 3,
          bgcolor: customBGColor ? customBGColor : theme.palette.custom.white,
          color: customBGColor ? theme.palette.custom.white : theme.palette.custom.primary,
          border: "5px",
          borderColor: theme.palette.custom.white,
          fontSize: 20,
        },
        () => ({
          "&:hover": {
            color: theme.palette.custom.white,
            bgcolor: customBGColorHover ? customBGColorHover : theme.palette.custom.primary,
            border: "5px",
            borderColor: theme.palette.custom.white,
          },
        }),
      ]}
    >
      Sign In
    </Button>
  );
};
