import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  Tooltip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { yearMonthDate } from "../../../utils/dateFormat";
import {
  holidayCardColor,
  leaveCardColor,
  textCardColor,
  wfoCardColor,
} from "../../../utils/color";

const LEAVETYPES = ["Private Leave", "Sick Leave", "", "Vacation Leave"];

export default function CalendarItem({
  date,
  month,
  year,
  isLoading,
  projects,
  leaves,
  workFromOffices,
  leave_index,
}) {
  const navigate = useNavigate();

  const calLeaveHour = (leave, day) => {
    if (leave.status == null || leave.status == "Reject") {
      return 0;
    }
    if (
      (new Date(leave.dateLeave).toDateString() ==
        new Date(day).toDateString() &&
        (leave.dateLeavePeriod == "Afternoon" ||
          leave.dateLeavePeriod == "Morning")) ||
      (new Date(leave.dateLeaveto) == new Date(day) &&
        (leave.dateLeaveToPeriod == "Afternoon" ||
          leave.dateLeaveToPeriod == "Morning"))
    ) {
      return 4;
    }
    return 8;
  };

  const leaveInfo = (leave, leave_index) => {
    let thisDay = new Date(year, month, leave_index + 1);
    let hour = calLeaveHour(leave, thisDay);
    return `Subject: ${leave.subject}\nDescription: ${
      leave.description
    }\nType: ${LEAVETYPES[leave.typeID - 1]}\nhour: ${hour}`;
  };
  const wfhInfo = (project) => {
    return `Project: ${project.projectName}\nHours:${project.hours}\nMinutes:${project.minutes}`;
  };
  const wfoInfo = (wfo) => {
    return `CheckIn: ${new Date(
      wfo.checkIn
    ).toLocaleTimeString()}\nCheckOut: ${new Date(
      wfo.checkOut
    ).toLocaleTimeString()}\nHours: ${wfo.hours}\nMinutes: ${wfo.minutes}`;
  };

  const wfhShowDetials = (project) => {
    return `WFH ${project.hours}h ${project.minutes}min`;
  };
  const wfoShowDetials = (wfo) => {
    return `WFO ${wfo.hours}h ${wfo.minutes}min`;
  };

  return (
    <>
      <Grid item xs={1}>
        <Box
          sx={{
            height: "140px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            border: "1px solid #ccc",
          }}
        >
          <Box mt={1.5} mb={1}>
            {date}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              width: 1,
              height: 1,
              overflow: "hidden",
            }}
          >
            {!isLoading ? (
              projects?.length > 0 ? (
                projects?.map((project) => (
                  <Tooltip
                    key={project.projectId}
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>
                        {project.color ? wfhInfo(project) : project.projectName}
                      </span>
                    }
                    placement="right"
                    followCursor
                  >
                    <Box
                      key={project.projectId}
                      sx={{
                        backgroundColor: project.color || holidayCardColor,
                        padding: "0.4rem 0.3rem",
                        width: "80%",

                        textAlign: "center",
                        borderRadius: "5px",
                        cursor: project.color ? "pointer" : "default",
                        ":hover": {
                          opacity: 0.8,
                        },
                      }}
                      onClick={() => {
                        if (project.color)
                          navigate(
                            `/activity/${yearMonthDate(
                              new Date(year, month, date)
                            )}`
                          );
                      }}
                    >
                      <Typography
                        noWrap={true}
                        sx={{ color: textCardColor, fontSize: "0.7rem" }}
                      >
                        {project.color
                          ? wfhShowDetials(project)
                          : project.projectName}
                      </Typography>
                    </Box>
                  </Tooltip>
                ))
              ) : (
                <></>
              )
            ) : (
              <CircularProgress />
            )}
            {!isLoading &&
              leaves?.length > 0 &&
              leaves?.map((leave) => (
                <Tooltip
                  key={leave.leaveId}
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {leaveInfo(leave, leave_index)}
                    </span>
                  }
                  placement="right"
                  followCursor
                >
                  <Box
                    key={leave.leaveId}
                    sx={{
                      backgroundColor: leaveCardColor,

                      padding: "0.4rem 0.3rem",
                      width: "80%",

                      textAlign: "center",
                      borderRadius: "5px",
                      cursor: "default",
                      ":hover": {
                        opacity: 0.8,
                      },
                    }}
                  >
                    <Typography
                      noWrap={true}
                      sx={{
                        color: textCardColor,
                        fontSize: "0.7rem",
                      }}
                    >
                      {leave.subject}
                    </Typography>
                  </Box>
                </Tooltip>
              ))}
            {!isLoading &&
              workFromOffices?.length > 0 &&
              workFromOffices?.map(
                (wfo) =>
                  wfo.hours != null && (
                    <Tooltip
                      key={wfo.count}
                      title={
                        <span style={{ whiteSpace: "pre-line" }}>
                          {wfoInfo(wfo)}
                        </span>
                      }
                      placement="right"
                      followCursor
                    >
                      <Box
                        key={wfo.count}
                        sx={{
                          backgroundColor: wfoCardColor,

                          padding: "0.4rem 0.3rem",
                          width: "80%",

                          textAlign: "center",
                          borderRadius: "5px",
                          cursor: "default",
                          ":hover": {
                            opacity: 0.8,
                          },
                        }}
                      >
                        <Typography
                          noWrap={true}
                          sx={{
                            color: textCardColor,
                            fontSize: "0.7rem",
                          }}
                        >
                          {wfoShowDetials(wfo)}
                        </Typography>
                      </Box>
                    </Tooltip>
                  )
              )}
          </Box>
        </Box>
      </Grid>
    </>
  );
}
