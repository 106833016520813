import { React, useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Stack, useTheme, Box, Typography } from "@mui/material";
import useWindowSize from "../hooks/useWindowSize";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { fetchUserSeenAnnouncement } from "../fetch/fetchUser";
import { fetchEmployeeID } from "../fetch/fecthActivity";
import MobileLayout from "./MobileLayout/MobileLayout";

export const getPageName = (pathname) => {
  const pageName = [
    "Announcement",
    "Activity",
    "Calendar",
    "Schedule",
    "Dashboard",
    "Teams",
  ];

  return pathname.includes("activity")
    ? "Activities"
    : pageName.find((i) =>
        pathname.includes(i.toLowerCase())
      ) != null
    ? pageName.find((i) => pathname.includes(i.toLowerCase()))
    : "Activities";
};

export default function MainLayout() {
  const location = useLocation();
  const theme = useTheme();
  const windowSize = useWindowSize();
  const [isHideSidebar, setIsHideSidebar] = useState(false);
  const handleHideSidebar = (width) => {
    if (width >= 1100) setIsHideSidebar((state) => !state);
  };
  const [isSeenAnnouncement, setIsSeenAnnouncement] = useState(true);

  useEffect(() => {
    refreshSeenAnnouncement();
    // var dayInMilliseconds = 1000 * 60 * 60 * 24;
    // setInterval(() => {
    //   refreshSeenAnnouncement();
    // }, dayInMilliseconds);
  }, []);

  const refreshSeenAnnouncement = async () => {
    var empId = await fetchEmployeeID();
    if (empId !== undefined && empId.e > 0) {
      var seenAnnouncementObj = await fetchUserSeenAnnouncement(empId.e);
      setIsSeenAnnouncement(seenAnnouncementObj.data);
    }
  };

  const isDesktop = windowSize.width > 600;

  return (
    <Stack
      direction={isDesktop ?"row" : "column"}
      sx={{
        width: "100vw",
        height: isDesktop ? "100vh" : "auto",
        overflow: "auto",
        fontFamily: "inter, sans-serif",
        backgroundColor: theme.palette.custom.background,
      }}
    >
      {isDesktop ? 
        <Stack
          direction="column"
          sx={{
          m: 0,
          maxWidth: 250,
          minHeight: 400,
          backgroundColor: theme.palette.custom.white,
          alignContent: "center",
          p: "20px 10px 10px 10px",}}
        >
          <Header
            isHideSidebar={isHideSidebar}
            handleHideSidebar={handleHideSidebar}
          />
          <Sidebar
            isHideSidebar={isHideSidebar}
            isSeenAnnouncement={isSeenAnnouncement}
            refreshSeenAnnouncement={refreshSeenAnnouncement}
          />
          <Footer isHideSidebar={isHideSidebar} />
        </Stack> : <MobileLayout />}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
        }}
      >
        <Typography
          sx={{
            fontSize: windowSize.width >= 470 ? 24 : 20,
            fontWeight: 700,
            ml: "20px",
            mt: "15px",
            mb: "10px",
          }}
        >
          {getPageName(location.pathname)}
        </Typography>

        <Box
          sx={{
            width: 0.9,
            height: 0.9,
            mx: "auto",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Stack>
  );
}
