const getTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
    return [getHours, getMinutes, getSeconds];
};

const getTimeNow = () => {
    let now = new Date();
    let startTime = localStorage.getItem("StartTime") ? parseInt(localStorage.getItem("StartTime")) : 0;
    let timeNow = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
    let newTime =  timeNow - startTime;

    return newTime;
};


export {
    getTime,
    getTimeNow
};