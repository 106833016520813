import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers/";

export default function LabelAndInputTime({
  taskStartTime,
  taskEndTime,
  taskHours,
  taskMinutes,
  setTaskStartTime,
  setTaskEndTime,
  setTaskHours,
  setTaskMinutes,
  workHoursOnly,
  setWorkHourOnly,
  resetStartTimeEndTime,
  isWorkFromHome
}) {
  const setStartAndEndTime = (houres, minutes) => {
    if (taskStartTime !== null && taskStartTime !== undefined) {
      let increaseHours = Number.parseInt(
        (Number.parseInt(taskStartTime.split(":")[1]) +
          Number.parseInt(minutes)) /
          60
      );

      setTaskEndTime(
        (
          (Number.parseInt(taskStartTime.split(":")[0]) +
            Number.parseInt(houres) +
            increaseHours) %
          24
        )
          .toString()
          .padStart(2, "0") +
          ":" +
          Number.parseInt(
            (Number.parseInt(taskStartTime.split(":")[1]) +
              Number.parseInt(minutes)) %
              60
          )
            .toString()
            .padStart(2, "0")
      );
    }
  };

  const setHoursMinutes = (start, end) => {
    let hmStart = start.split(":");
    let hmEnd = end.split(":");
    let H, m;

    if (Number.parseInt(hmEnd[1]) < Number.parseInt(hmStart[1])) {
      H = Number.parseInt(hmEnd[0]) - Number.parseInt(hmStart[0]) - 1;
      m = 60 + Number.parseInt(hmEnd[1]) - Number.parseInt(hmStart[1]);
      setTaskHours(H);
      setTaskMinutes(m);
      return true;
    }

    H = Number.parseInt(hmEnd[0]) - Number.parseInt(hmStart[0]);
    m = Number.parseInt(hmEnd[1]) - Number.parseInt(hmStart[1]);

    setTaskHours(H);
    setTaskMinutes(m);
  };

  const inputHoursChange = (value) => {
    if (value >= 0 && value < 24) setTaskHours(() => setTaskHours(value));
    if (value == 24 && taskMinutes != 0)
      setTaskMinutes(() => setTaskMinutes(0));
    if (value == "") {
      setTaskHours(() => setTaskHours(0));
    }
    if (value == 0 && taskMinutes == 0) {
      setTaskMinutes(1);
      return setStartAndEndTime(value, 1);
    }
    return setStartAndEndTime(value, taskMinutes);
  };

  const inputMinutesChange = (value) => {
    if (
      (taskHours == 0 && value != 0) ||
      (taskHours != 0 && value >= 0 && value < 60)
    ) {
      setTaskMinutes(() => setTaskMinutes(value));
      return setStartAndEndTime(taskHours, value);
    }
    if (value == "") {
      setTaskMinutes(() => setTaskMinutes(0));
      return setStartAndEndTime(taskHours, value);
    }
  };

  // const setEndHoursMinutes = (Hours, Minutes) => {
  //   if (Minutes == 59) return { Minutes: 0, sHours: Hours + 1 };
  //   return { Minutes: Minutes + 1, Hours: Hours };
  // };

  const inputStartTimeChange = (value) => {
    if (value == null) return setTaskStartTime("");
    if (value == "Invalid Date") return setTaskStartTime("Invalid");

    let time = new Date(value);
    let taskStartDate = new Date(`0 ${value.getHours()}:${value.getMinutes()}`);
    let taskEndDate = new Date(`0 ${taskEndTime}`);

    // if (taskStartDate >= taskEndDate) {
    //   let eHours = Number.parseInt(time.getHours());
    //   let eMinutes = Number.parseInt(time.getMinutes());
    //   let endTime = setEndHoursMinutes(eHours, eMinutes);
    //   let end =
    //     endTime.Hours.toString().padStart(2, "0") +
    //     ":" +
    //     endTime.Minutes.toString().padStart(2, "0");
    //   setTaskEndTime(end);
    //   return setHoursMinutes(taskStartTime, taskEndTime);
    // }

    if (taskStartDate <= taskEndDate) {
      let newStartTimeStr = `${time
        .getHours()
        .toString()
        .padStart(2, "0")}:${time.getMinutes().toString().padStart(2, "0")}`;
      setTaskStartTime(newStartTimeStr);

      return setHoursMinutes(
        time.getHours() + ":" + time.getMinutes(),
        taskEndTime
      );
    }
  };

  // const setStartHoursMinutes = (Hours, Minutes) => {
  //   if (Minutes == 0) return { Minutes: 59, Hours: Hours - 1 };
  //   return { Minutes: Minutes - 1, Hours: Hours };
  // };

  const inputEndTimeChange = (value) => {
    if (value == null) return setTaskEndTime("");
    if (value == "Invalid Date") return setTaskEndTime("Invalid");

    // var newDateValue = new Date(`0 ${value.getHours()}:${value.getMinutes()}`);
    // var oldDateValue = new Date(`0 ${taskEndTime}`);

    // if (newDateValue <= oldDateValue) {
    //   let sHours = Number.parseInt(time.getHours());
    //   let sMinutes = Number.parseInt(time.getMinutes());
    //   let startTime = setStartHoursMinutes(sHours, sMinutes);
    //   let start =
    //     startTime.Hours.toString().padStart(2, "0") +
    //     ":" +
    //     startTime.Minutes.toString().padStart(2, "0");
    //   setTaskStartTime(start);
    //   return setHoursMinutes(start, time.getHours() + ":" + time.getMinutes());
    // }

    let time = new Date(value);
    let taskStartDate = new Date(`0 ${taskStartTime}`);
    let taskEndDate = new Date(`0 ${value.getHours()}:${value.getMinutes()}`);

    if (taskStartDate <= taskEndDate) {
      let newEndTimeStr = `${time.getHours().toString().padStart(2, "0")}:${time
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
      setTaskEndTime(newEndTimeStr);

      return setHoursMinutes(
        taskStartTime,
        time.getHours() + ":" + time.getMinutes()
      );
    }
  };

  const handleCheckboxChange = (checked) => {
    if (checked) {
      setTaskStartTime(null);
      setTaskEndTime(null);
      setWorkHourOnly(checked);
      return;
    }

    resetStartTimeEndTime();

    if (taskStartTime == null && taskEndTime == null) {
      setTaskStartTime("09:00");
      setTaskEndTime("09:01");
      setHoursMinutes("09:00", "09:01");
    }

    setWorkHourOnly(checked);
  };

  return (
    <>
      <Grid item xs={2}>
        <Typography fontWeight={500} textAlign={"right"}>
          Time
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ ml: 2 }}
                checked={workHoursOnly}
                onChange={(e) => handleCheckboxChange(e.target.checked)}
              />
            }
            label="Enter work hours only"
          />
        </FormGroup>
      </Grid>
      {!workHoursOnly && (
        <>
          <Grid item xs={2}>
            <Typography fontWeight={500} textAlign={"right"}>
              Start
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                sx={
                  taskStartTime === ""
                    ? {
                        borderColor: "red",
                      }
                    : {}
                }
                value={new Date(`0 ${taskStartTime}`)}
                onChange={inputStartTimeChange}
                ampm={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{ ml: 2 }}
                    error={taskStartTime === "" || taskStartTime === "Invalid"}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <Typography textAlign={"right"} fontWeight={500}>
              End
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                value={new Date(`0 ${taskEndTime}`)}
                onChange={inputEndTimeChange}
                ampm={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{ ml: 2 }}
                    error={taskEndTime === "" || taskEndTime === "Invalid"}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </>
      )}
      {workHoursOnly && (
        <>
          <Grid item xs={2}>
            <Typography textAlign={"right"} fontWeight={500} sx={{ my: 1 }}>
              Hours
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <TextField
                size="small"
                type="number"
                value={taskHours}
                onChange={(e) => inputHoursChange(e.target.value)}
                inputProps={{ inputprops: { min: 0, max: 24 } }}
                sx={{ width: 75 }}
              />
              <Typography fontWeight={500}>Minutes</Typography>
              <TextField
                size="small"
                type="number"
                value={taskMinutes}
                onChange={(e) => inputMinutesChange(e.target.value)}
                inputProps={{ inputprops: { min: 0, max: 24 } }}
                sx={{ width: 75 }}
                error={taskHours === 0 && taskMinutes === 0 && isWorkFromHome}
              />
            </Stack>
          </Grid>
        </>
      )}
    </>
  );
}
