//import { Container } from "@material-ui/core";
import React from "react";
import Page500Component from "../components/ResponsePageComponent/Page500Component";

export default function InternalError() {
  return (
    <>
      <Page500Component />
    </>
  );
}
