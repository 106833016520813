import { makeStyles } from "@material-ui/core/styles";

const LINES_TO_SHOW = 15;
export const useStyles = makeStyles(() => ({
  Table_detail: {
    fontSize: "12px",
  },
  Table_title: {
    fontSize: "12px",
    fontWeight: "bold",
    verticalAlign: "top",
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#F7F7FD",
    color: "#F7F7FD",
  },
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
  },
}));
