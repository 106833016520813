import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditorConfig } from "../../../utils/ckEditorConfig";
import "../rte.css";

export default function InputTaskDescription({ detail, setDetail }) {
  return (
    <div className="custom-rte">
      <CKEditor
        editor={ClassicEditor}
        config={CKEditorConfig}
        data={detail}
        onChange={(event, editor) => {
          const data = editor.getData();
          setDetail(data);
        }}
      />
    </div>
  );
}
