import React from "react";

import { Typography, Box, Stack, useTheme } from "@mui/material";

import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useWindowSize from "../hooks/useWindowSize";
import { red } from "@mui/material/colors";

import { useNavigate, useLocation } from "react-router";

export const SlideBarItems = [
  {
    id: 5,
    icon: <CampaignIcon sx={{ fontSize: 20 }} />,
    name: "Announcement",
    path: "/announcement",
    newIcon: <ErrorOutlineIcon sx={{ fontSize: 20, color: red[500] }} /> 
  },
  {
    id: 0,
    icon: <AddTaskOutlinedIcon sx={{ fontSize: 20 }} />,
    name: "Activity",
    path: "/activity",
    newIcon: ""
  },
  {
    id: 1,
    icon: <CalendarTodayOutlinedIcon sx={{ fontSize: 20 }} />,
    name: "Calendar",
    path: "/calendar",
    newIcon: ""
  },
  {
    id: 2,
    icon: <TimelineOutlinedIcon sx={{ fontSize: 20 }} />,
    name: "Schedule",
    path: "/schedule",
    newIcon: ""
  },
  {
    id: 3,
    icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
    name: "Dashboard",
    path: "/dashboard",
    newIcon: ""
  },
  {
    id: 4,
    icon: <PeopleAltOutlinedIcon sx={{ fontSize: 20 }} />,
    name: "Teams",
    path: "/teams",
    newIcon: ""
  },
];

export default function Sidebar({ isHideSidebar, isSeenAnnouncement, refreshSeenAnnouncement }) {
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const refreshUserSeenAnnouncement = (path) => {
    if (!isSeenAnnouncement && path !== undefined && path === "Announcement") {
      setTimeout(() => {
        refreshSeenAnnouncement();
      }, 300);
    }
  };

  return (
    <Stack direction="column" spacing="10px">
      {SlideBarItems.map((i) => (
        <Box
          key={i.id}
          onClick={() => {
            navigate(`${i.path}`);
            refreshUserSeenAnnouncement(`${i.name}`);
          }}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            borderRadius: "5px",
            p: "10px 10px",
            m: 0,
            backgroundColor:
              location.pathname === i.path ||
              (i.name == "Activity" && location.pathname.includes("-"))
                ? theme.palette.custom.primary
                : "transparent",
            color:
              location.pathname === i.path ||
              (i.name == "Activity" && location.pathname.includes("-"))
                ? theme.palette.custom.white
                : theme.palette.custom.defaultTextColor,
          }}
        >
          {i.icon}
          {!(isHideSidebar || windowSize.width < 1100) && (
            <Typography
              sx={{
                marginLeft: "10px",
                fontSize: 16,
                fontWeight: 500,
                paddingRight: 1
              }}
            >
              {i.name}
            </Typography>
          )}
          {!(isSeenAnnouncement) && (
            <span>
              {i.newIcon}
            </span>
          )}
        </Box>
      ))}
    </Stack>
  );
}
