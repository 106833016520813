import { axiosHelper } from "../utils/axiosHelper";
import { clearAllTokens } from "../utils/tokenHelper";

export const getServerTokenWithAzureAD = async (token, email) => {
  const aadCustomHeader = {
    Authorization: `Bearer ${token}`,
  };

  const res = await axiosHelper.get(
    "/api/Users/WFHLoginWithAzureAD",
    {
      email: email,
    },
    aadCustomHeader
  );

  if (res.status == 200) {
    localStorage.setItem("serverToken", res.data);
    return await getEmployeeInfo();
  }
  return false;
};

export const getEmployeeInfo = async () => {
  const res = await axiosHelper.get("/api/Users/GetEmployeeByToken");

  if (res && res.status == 200) {
    localStorage.setItem("user", JSON.stringify(res.data));
    return true;
  }
  else if (res && res.status == 401) {
    clearAllTokens();
  }

  return false;
};
