import { FormControl, Select, MenuItem } from "@mui/material";

export default function ProjectSelector({
  taskProject,
  setTaskProject,
  projects,
}) {
  return (
    <FormControl sx={{ width: 1, minWidth: 170 }} size="small">
      <Select
        value={taskProject.id}
        name={taskProject.label}
        onChange={(e) => {
          console.log(e);
          setTaskProject({
            label: e.target.name,
            id: e.target.value,
          });
        }}
      >
        {projects.map((p) => (
          <MenuItem key={p.label} value={p.id}>
            {p.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
