import React from "react";

import { Divider } from "@mui/material";

import DateAndWorkHoursCard from "./DateAndWorkHoursCard";

function DateAndWorkHoursCardWrap({
  dateStr,
  weekDetails,
  mainProjectSelected,
  holidays,
  loadStatus,
  index
}){
  return (
    <>
      <DateAndWorkHoursCard
        date={dateStr[0] + ", " + dateStr[2] + " " + dateStr[1]}
        dayDetails={weekDetails[index]}
        mainProjectSelected={mainProjectSelected}
        isHoliday={holidays?.data?.[index].length != 0}
        isSunSat={index == 0 || index == 6}
        loadStatus={loadStatus}
      />
      <Divider />
    </>
  );
};

export default function DateCardsForWeek({
  dateSelected,
  weekDetails,
  mainProjectSelected,
  holidays,
  loadStatus,
}) {
  let dateCardArr = [];
  let date = new Date(dateSelected);
  date.setHours(-24 * dateSelected.getDay());
  for (let i = 0; i < 7; i++) {
    let dateStr = date.toString().split(" ");
    dateCardArr.push(
      <DateAndWorkHoursCardWrap 
        key={i}
        dateStr={dateStr}
        weekDetails={weekDetails}
        mainProjectSelected={mainProjectSelected}
        holidays={holidays}
        loadStatus={loadStatus}
        index={i}
      />

      
    );
    date.setHours(24);
  }
  return dateCardArr;
}
