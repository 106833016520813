import React, { useEffect, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Announcement, SignInButton } from "../components/LoginComponents";
import useWindowSize from "../hooks/useWindowSize";

export default function Login() {
  const navigate = useNavigate();
  const theme = useTheme();
  const windowSize = useWindowSize();
  const [idxLogin, setIdxLogin] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("user") && localStorage.getItem("serverToken")) {
      navigate("/activity");
    }
  }, [idxLogin]);

  return (
    <Box
      sx={{
        margin: 0,
        height: "100vh",
        width: 1,
        display: "flex",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.custom.white,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: windowSize.width >= 1100 ? "center" : "start",
          alignItems: "center",
          minHeight: "fit-content",
          Height: 1,
          py: 3,
        }}
      >
        {windowSize.width < 1100 && (
          <Typography
            align="center"
            sx={{
              color: theme.palette.custom.defaultTextColor,
              fontWeight: 400,
              fontSize: 25,
              mt: "6rem",
              mb: "2rem",
            }}
          >
            Welcome to Mycos Task Tracker
          </Typography>
        )}
        <Announcement />
        <Box sx={{ height: 50 }}></Box>
        {windowSize.width < 1100 && (
          <SignInButton
            customVariant={"contained"}
            customBGColor={theme.palette.custom.primary}
            customBGColorHover={theme.palette.custom.lightPrimary}
            setIdxLogin={setIdxLogin}
          />
        )}
      </Box>
      {windowSize.width >= 1100 && (
        <Box
          sx={{
            backgroundColor: theme.palette.custom.primary,
            width: 0.25,
            maxWidth: 300,
            display: "flex",
            flexDirection: "column ",
            px: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            alt="MycosLogo"
            src="/images/mycostech.png"
            sx={{
              height: 150,
              width: 150,
              backgroundColor: theme.palette.custom.white,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Box>
          <Box sx={{ my: 10 }}>
            <Typography
              align="center"
              sx={{
                color: theme.palette.custom.white,
                fontWeight: 400,
                fontSize: 25,
                my: 4,
              }}
            >
              Mycos Task Tracker
            </Typography>
          </Box>
          <SignInButton
            customVariant={null}
            customBGColor={null}
            customBGColorHover={null}
            setIdxLogin={setIdxLogin}
          />
        </Box>
      )}
    </Box>
  );
}
