import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";

export default function StatusSelector({
  statusList,
  status,
  setStatus,
  width,
}) {
  const theme = useTheme();
  return (
    <FormControl
      color="inputColor"
      sx={{ width: width, minWidth: 200 }}
      size="small"
    >
      <InputLabel>Status</InputLabel>
      <Select
        required
        //disablePortal
        labelId="demo-select-small"
        id="demo-select-small"
        value={status}
        label="Status"
        onChange={(e) => {
          setStatus(e.target.value);
        }}
        sx={{
          input: { color: theme.palette.custom.white },
          color: theme.palette.custom.white,
        }}
        inputlabelprops={{
          style: { color: theme.palette.custom.white },
        }}
      >
        {statusList.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
