import React, { useState } from "react";

import { Grid } from "@mui/material";
import { Box } from "@mui/system";

import {
  DayName,
  CalendarItem,
  ProjectLabel,
} from "./CalendarContentComponents";

import { useQuery } from "react-query";
import useWindowSize from "../../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";

import { fetchProjectInMonth } from "../../fetch/fecthActivity";
import { fetchHoliday, fetchEmployeeLeave } from "../../fetch/fetchDayOff";
import { fetchWFOAttendance } from "../../fetch/fetchWFOAttendance";
import { yearMonthDate } from "../../utils/dateFormat";
import { getDaysInMonthArray } from "../../utils/calendarHelper";

const DAY_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default function CalendarContent({ month, year }) {
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const [projectWithHolidays, setProjectWithHolidays] = useState([]);
  const [projectColors, setProjectColors] = useState([]);

  const convertToYYYY_MM_DDFormat = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const { data: projects, isLoading: isProjectsLoading } = useQuery(
    ["projects", month + 1, year],
    () =>
      fetchProjectInMonth(month + 1, year).then((res) => {
        setProjectColors(res.projectColors);
        return res.data;
      }),
    {
      onError: () => {
        navigate("/error500");
      },
    }
  );

  const { isLoading: isHolidaysLoading } = useQuery(
    ["holidays", month + 1, year],
    () =>
      fetchHoliday(
        yearMonthDate(new Date(year, month, 1)),
        yearMonthDate(new Date(year, month + 1, 0))
      ).then((res) => res.data),
    {
      enabled: !isProjectsLoading,
      onSuccess: (res) => {
        try {
          let projectWithHolidays = [];
          projects?.forEach((project, i) => {
            projectWithHolidays?.push([
              ...project,
              ...res?.[i]?.map((h) => {
                return {
                  projectId: h.holidayName,
                  projectName: h.holidayName,
                  date: h.holidayDate,
                };
              }),
            ]);
          });
          setProjectWithHolidays(projectWithHolidays);
        } catch {
          navigate("/error500");
        }
      },
    }
  );
  const { data: workFromOffices, status: isWorkFromOfficesLoading } = useQuery(
    ["workFromOfficesToken", month + 1, year],
    () => {
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      firstday = convertToYYYY_MM_DDFormat(firstday);
      lastday = convertToYYYY_MM_DDFormat(lastday);

      return fetchWFOAttendance(firstday, lastday);
    },
    {
      onError: () => {
        navigate("/error500");
      },
    }
  );

  const { data: leaveDays, status: isLeaveDaysLoading } = useQuery(
    ["leaveDayToken", month + 1, year],
    () => {
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      firstday = convertToYYYY_MM_DDFormat(firstday);
      lastday = convertToYYYY_MM_DDFormat(lastday);

      return fetchEmployeeLeave(firstday, lastday);
    },
    {
      onError: () => {
        navigate("/error500");
      },
    }
  );

  const isLoading =
    isHolidaysLoading !== "success" &&
    isWorkFromOfficesLoading !== "success" &&
    isLeaveDaysLoading !== "success";

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        {windowSize.width > 900 ? (
          <ProjectLabel projectColors={projectColors} />
        ) : (
          <></>
        )}
        <Grid container columns={windowSize.width > 800 ? 7 : 4} spacing={0}>
          {windowSize.width > 800 ? (
            DAY_NAMES.map((dayName) => (
              <DayName key={dayName} dayName={dayName} />
            ))
          ) : (
            <></>
          )}
          {getDaysInMonthArray(month, year).map((date, index) => (
            <CalendarItem
              key={index}
              date={date}
              month={month}
              year={year}
              projects={date === null ? [] : projectWithHolidays?.[date - 1]}
              leaves={date === null ? [] : leaveDays?.data?.[date - 1]}
              workFromOffices={
                date === null ? [] : workFromOffices?.data?.[date - 1]
              }
              leave_index={date - 1}
              isLoading={isLoading}
            />
          ))}
        </Grid>
      </Box>
    </>
  );
}
