export const CKEditorConfig = {
    toolbar: {
		items: [
			'bold',
			'italic',
			'underline',
			'link',
			'removeFormat',
			'|',
			'undo',
			'redo'
		]
	},
	language: 'en'
};