import React, { useEffect, useState } from "react";
import { Container, Stack, useTheme } from "@mui/material";
import TeamActivityFilter from "../components/TeamActivityComponents/TeamActivityFilter";
import WorkHoursTable from "../components/TeamActivityComponents/WorkHoursTable";
import StatusWorkHoursBadge from "../components/TeamActivityComponents/StatusWorkHoursBadge";
import WorkHistoryTeamBadge from "../components/TeamActivityComponents/WorkHistoryTeamBadge";
import ActivityDetailsModal from "../components/TeamActivityComponents/ActivityDetailsModal";

import {
  fetchEmployeeTeam,
  fetchTeamWorkHoursDetail,
} from "../fetch/fetchTeam";
import moment from "moment";

export default function TeamActivity() {
  let yesterday = new Date(new Date().valueOf() - 1 * 1000 * 60 * 60 * 24).setHours(23, 59, 59);
  let lastweek = new Date(
    new Date().valueOf() - 7 * 1000 * 60 * 60 * 24
  ).setHours(0, 0, 0);

  const theme = useTheme();
  const [pageW, setPageW] = useState(0);
  const [startDate, setStartDate] = useState(lastweek);
  const [endDate, setEndDate] = useState(yesterday);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [projects, setProjects] = useState([]);
  const [workHoursDetail, setWorkHoursDetail] = useState([]);

  const [openActivityDetailsModal, setOpenActivityDetailsModal] =
    useState(false);
  const [selectedWork, setSelectedWork] = useState(null);

  const fetchEmployeeTeamData = async () => {
    const employeeTeam = await fetchEmployeeTeam();
    if (
      employeeTeam &&
      employeeTeam.status === 200 &&
      employeeTeam.data &&
      employeeTeam.data.projectList &&
      employeeTeam.data.projectList.length > 0
    ) {
      setSelectedProjectId(employeeTeam.data.projectList[0].projectId);
      setProjects(employeeTeam.data.projectList);
      fetchTeamWorkHours(employeeTeam.data.projectList[0].projectId);
    }
  };

  const fetchTeamWorkHours = async (defaultProjectId) => {
    const startDateStr = moment(startDate).format("YYYY-MM-DD");
    const endDateStr = moment(endDate).format("YYYY-MM-DDT23:59:59");

    const teamWorkHour = await fetchTeamWorkHoursDetail(
      startDateStr,
      endDateStr,
      0,
      selectedProjectId ? selectedProjectId : defaultProjectId
    );

    if (teamWorkHour && teamWorkHour.status === 200 && teamWorkHour.data) {
      setWorkHoursDetail(teamWorkHour.data);
    } else {
      console.log("Something went wrong while fetching data from API.");
      setWorkHoursDetail([]);
    }
  };

  const handleOpenActivityDetailsModal = () => {
    setOpenActivityDetailsModal(true);
  };

  const handleCloseActivityDetailsModal = () => {
    setOpenActivityDetailsModal(false);
  };

  useEffect(() => {
    fetchEmployeeTeamData();
  }, []);

  const onSearchClick = () => {
    fetchTeamWorkHours();
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        background: theme.palette.custom.white,
        borderRadius: "5px",
        minHeight: "100%",
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: 1,
          height: 1,
          minHeight: 600,
          mx: "auto",
          mb: 4,
          backgroundColor: theme.palette.custom.white,
          borderRadius: "5px",
        }}
      >
        <TeamActivityFilter
          startDate={startDate}
          endDate={endDate}
          selectedProjectId={selectedProjectId}
          projects={projects}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setSelectedProjectId={setSelectedProjectId}
          onSearchClick={onSearchClick}
          fetchEmployeeTeamData={fetchEmployeeTeamData}
        />
        <StatusWorkHoursBadge />
        <WorkHistoryTeamBadge projects={projects} />
        <WorkHoursTable
          setPage={setPageW}
          page={pageW}
          workHoursDetail={workHoursDetail}
          setSelectedWork={setSelectedWork}
          handleOpenActivityDetailsModal={handleOpenActivityDetailsModal}
        />
        <ActivityDetailsModal
          projects={projects}
          selectedWork={selectedWork}
          openActivityDetailsModal={openActivityDetailsModal}
          handleCloseActivityDetailsModal={handleCloseActivityDetailsModal}
        />
      </Stack>
    </Container>
  );
}
