import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useNavigate } from "react-router-dom";
import { getEmployeeInfo } from "../fetch/fetchAuthentication";
import toast from "react-hot-toast";

const AuthContext = React.createContext();

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const login = async () => {
    return instance
      .loginPopup(loginRequest)
      .then((r) => {
        if (r && r.accessToken && r.account) {
          localStorage.setItem("user", JSON.stringify(r.account));
          return r;
        } 
        else {
          return null;
        }
      })
      .catch((e) => {
        //toast.error(e);
        console.log(e);
        sessionStorage.clear();
        return null;
      });
  };

  const logout = () => {
    instance
      .logoutPopup({
        account: getUser(),
        postLogoutRedirectUri: "/",
      })
      .then(() => {
        toast.success("Logged out successfully");
        localStorage.removeItem("user");
        localStorage.removeItem("serverToken");
        sessionStorage.clear();
        navigate("/login");
      })
      .catch((e) => {
        console.error(e);
        toast.error("Logout failed");
      });
  };

  const getUser = async () => {
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user"));
    }

    const result = await getEmployeeInfo();

    if (result) return JSON.parse(localStorage.getItem("user"));

    return null;
  };

  const isLoggedIn = () => {
    return getUser() !== null && localStorage.getItem("serverToken") !== null;
  };

  const value = {
    login,
    logout,
    getUser,
    isLoggedIn,
  };

  const contextValue = React.useMemo(() => value, [value]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}
