import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Box, Button, Stack, Typography } from "@mui/material";

import useWindowSize from "../../../hooks/useWindowSize";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function CustomPieChart({
  bgColor,
  setProjectDetail,
  projects,
  totalHours,
}) {
  const windowSize = useWindowSize();

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels: projects.map((p) => p.projectName),
    datasets: [
      {
        label: "# of Votes",
        data: totalHours,
        backgroundColor: bgColor,
      },
    ],
  };

  return (
    <Stack
      direction={windowSize.width >= 1100 ? "row" : "column"}
      spacing={2}
      alignItems={"center"}
      p={2}
    >
      <Box
        sx={{
          width: windowSize.width >= 1100 ? "50%" : "75%",
          maxWidth: windowSize.width >= 1100 ? 400 : 200,

          maxHeight: windowSize.width >= 1100 ? 400 : 200,
        }}
      >
        {totalHours.every((value) => value === 0) ? (
          <Box width={1} sx={{ textAlign: "center" }}>
            <Typography>No data to display</Typography>
          </Box>
        ) : (
          <Box width={1}>
            <Pie options={options} data={data} />
          </Box>
        )}
      </Box>

      <Stack
        spacing={1}
        direction="column"
        alignItems={windowSize.width >= 1100 ? "initial" : "center"}
        sx={{
          width: windowSize.width >= 1100 ? "50%" : "100%",

          justifyContent: "center",
          pl: 2,
        }}
      >
        {projects.map((p, index) => (
          <Stack spacing={1} direction={"row"} key={p.projectName}>
            <Box
              sx={{
                bgcolor: bgColor[index],
                p: 1,
              }}
            ></Box>
            <Typography>
              <Button
                sx={{ m: 0, p: 0, justifyItems: "flex-start" }}
                onClick={() => {
                  if (p.projectName != "WFO") setProjectDetail(p);
                }}
              >
                {p.projectName}
              </Button>
            </Typography>
            <Typography>
              {totalHours[index] ? totalHours[index].toFixed(2) : "0.00"} Hours
            </Typography>
            <Typography>
              {totalHours[index]
                ? (
                    (totalHours[index] * 100) /
                    totalHours.reduce((a, b) => a + b, 0)
                  ).toFixed(2)
                : "0.00"}
              %
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
