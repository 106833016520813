import React, { useEffect, useState } from "react";

import {
  useTheme,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  InputLabel,
  TextField,
  Container,
  Grid,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { getHourMinString } from "../../utils/workhourHelper";
import CloseIcon from "@mui/icons-material/Close";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CKEditorConfig } from "../../utils/ckEditorConfig";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { sanitizeHtmlContent } from "../../utils/stringHelper";
import { unescape } from "html-escaper";

export default function ActivityDetailsModal({
  projects,
  selectedWork,
  openActivityDetailsModal,
  handleCloseActivityDetailsModal,
}) {
  const theme = useTheme();
  const [projectName, setProjectName] = useState("");
  const [workDetails, setWorkDetails] = useState("");

  useEffect(() => {
    if (projects && selectedWork) {
      var currentProject = projects.filter(
        (x) => x.projectId == selectedWork.projectId
      );
      if (currentProject && currentProject.length > 0) {
        setProjectName(currentProject[0].projectName);
      } else {
        //Work From Office
        setProjectName(projects[0].projectName);
      }

      //Sanitize "\n" in work details content with new line
      if (selectedWork && selectedWork.inOutDetail) {
        var workDetailsSanitized = selectedWork.inOutDetail.replaceAll(
          /\\n/g,
          "\r\n"
        );
        workDetailsSanitized = sanitizeHtmlContent(
          unescape(workDetailsSanitized)
        );

        if (selectedWork.isWorkFromHome) {
          setWorkDetails(workDetailsSanitized);
        } else {
          setWorkDetails(`
            Work From Office
            ${workDetailsSanitized}
          `);
        }
      }
    }
  }, [projects, selectedWork]);

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={openActivityDetailsModal}
      onClose={handleCloseActivityDetailsModal}
    >
      <DialogTitle
        color={theme.palette.custom.defaultTextColor}
        fontSize={20}
        fontWeight={600}
      >
        {"Activity Details"}
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleCloseActivityDetailsModal}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Container maxWidth="lg">
          <Stack spacing={1}>
            <Grid container spacing={2} style={{ marginLeft: 0 }}>
              <Grid item xs={2}>
                <InputLabel>Project</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  value={projectName}
                  size="small"
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <InputLabel>Details</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <div
                  className="custom-rte custom-rte-edit custom-rte-disable"
                  style={{ overflowX: "hidden" }}
                >
                  <CKEditor
                    editor={ClassicEditor}
                    config={CKEditorConfig}
                    data={workDetails}
                    disabled={true}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <InputLabel>Date</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={selectedWork ? selectedWork.date : ""}
                    inputFormat={"dd/MM/yyyy"}
                    disabled={true}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    onChange={() => {}}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <InputLabel>Check In</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    value={selectedWork ? selectedWork.checkIn : ""}
                    inputFormat={"dd/MM/yyyy hh:mm a"}
                    disabled={true}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    onChange={() => {}}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <InputLabel>Check Out</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    value={selectedWork ? selectedWork.checkOut : ""}
                    inputFormat={"dd/MM/yyyy hh:mm a"}
                    disabled={true}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    onChange={() => {}}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <InputLabel>Work Hours</InputLabel>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  value={
                    selectedWork ? getHourMinString(selectedWork.workHours) : ""
                  }
                  size="small"
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
