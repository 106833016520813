import React from "react";

import { Button } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";

export const SignOutButton = () => {
  const { logout } = useAuth();

    return (
        <Button
            onClick={() => logout()}
            sx={{
            m: 0,
            marginLeft: "10px",
            fontSize: 13,
            fontWeight: 300,
            }}
        >
            Sign out
        </Button>
    );
};
