import sanitizeHtml from "sanitize-html";

export const replaceDoubleQuote = (str) => {
    //Need to replace double quote with single queto to make WFH.Notification process still render correctly.
    if (str) {
        const replaced = str.replaceAll(/"/g, "'");
        return replaced;
    }
    return str;
};

export const sanitizeHtmlContent = (dirty) => {
    var result = sanitizeHtml(dirty, {
        allowedTags: ["h1", "h2", "h3", "h4", "h5", "h6", "blockquote", "p", "ul", "ol",
            "nl", "li", "b", "i", "strong", "em", "strike", "hr", "br", "div", "span", "a"],
        allowedAttributes: {
            "*": ["style", "href", "target"]
        },
        allowedStyles: {
            "*": {
                // Match HEX, RGB and Color Name
                "background-color": [/(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))/],
                "color": [/(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))/],
                "text-align": [/^left$/, /^right$/, /^center$/],
                // Match any number with px, em, or %
                "font-size": [/^\d+(?:px|em|%|pt)$/],
                "font-family": [/[^]*/],
                "width": [/^\d+(?:px|em|%)$/],
                "height": [/^\d+(?:px|em|%)$/],
            }
        }
    });

    return result;
};

export const sanitizeNoHtmlTagContent = (dirty) => {
    var result = sanitizeHtml(dirty, {
        allowedTags: [],
        allowedAttributes: {},
    });

    console.log(result);

    return result;
};