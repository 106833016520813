import { useState, useRef } from "react";
import { getTimeNow } from "../utils/time";

export default function useTimer(initialState = 0) {
  const [timer, setTimer] = useState(initialState);
  const [isActive, setIsActive] = useState(false);
  const countRef = useRef(null);

  const handleReset = () => {
    clearInterval(countRef.current);
    setIsActive(false);
    setTimer(0);
  };
  
  const handleResume = () => {
    setIsActive(true);
    countRef.current = setInterval(() => {
      let newTime =  getTimeNow();
      setTimer(newTime);
    }, 1000);
  };

  return {
    timer,
    setTimer,
    isActive,
    handleReset,
    handleResume,
  };
}
