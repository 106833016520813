import React from "react";

import { Stack, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import {
  leaveCardColor,
  holidayCardColor,
  wfoCardColor,
} from "../../../utils/color";

export default function ProjectLabel({ projectColors }) {
  const theme = useTheme();

  let copy = JSON.parse(JSON.stringify(projectColors));

  copy.push(
    {
      projectId: "WFO",
      projectName: "WFO",
      color: wfoCardColor,
    },
    {
      projectId: "Holiday",
      projectName: "Holiday",
      color: holidayCardColor,
    },
    {
      projectId: "Leave",
      projectName: "Leave",
      color: leaveCardColor,
    }
  );

  return (
    <>
      <Box
        sx={{
          background: theme.palette.custom.white,
          width: 0.2,
        }}
      >
        <Box
          sx={{
            color: theme.palette.custom.primary,
            fontSize: "1.15rem",
            fontWeight: "600",
          }}
        >
          Color Indicator
        </Box>
        <Stack mt={2} spacing={2}>
          {copy.map((projectColor, index) => (
            <Label key={index} projectColor={projectColor} />
          ))}
        </Stack>
      </Box>
    </>
  );
}

function Label({ projectColor }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        gap: "15px",
        color: theme.palette.custom.primary,
      }}
    >
      <Box
        sx={{
          width: 20,
          height: 20,
          borderRadius: "50%",
          backgroundColor: projectColor.color,
        }}
      />
      {projectColor.projectName}
    </Box>
  );
}
