import React from "react";

import { Box, Stack, Divider, CircularProgress } from "@mui/material";

import {
  DateCardsForWeek,
  ActivityCard,
  TimeScale,
} from "./ScheduleContentComponents";

import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { fetchSchedule, fetchTotalHours } from "../../fetch/fecthActivity";
import { fetchEmployeeLeave, fetchHoliday } from "../../fetch/fetchDayOff";
import { fetchWFOAttendance } from "../../fetch/fetchWFOAttendance";

import { colorsForCard } from "../../utils/color";

const COLORS = colorsForCard;
const LEAVETYPES = ["Private Leave", "Sick Leave", "", "Vacation Leave"];

export default function ScheduleContent({
  dateSelected,
  firstday,
  lastday,
  mainProjectSelected,
}) {
  let projectColors = [];

  const navigate = useNavigate();

  const offset1 = firstday.getTimezoneOffset();
  const offset2 = lastday.getTimezoneOffset();
  const weekDetails = [];

  firstday = new Date(firstday.getTime() - offset1 * 60 * 1000);
  lastday = new Date(lastday.getTime() - offset2 * 60 * 1000);

  const getDateArray = (startDate, endDate) => {
    const dateArray = [];
    let date = new Date(startDate);
    while (date <= endDate) {
      dateArray.push(new Date(date).toLocaleDateString());
      date = new Date(date.setDate(date.getDate() + 1));
    }
    return dateArray;
  };
  const listDate = getDateArray(firstday, lastday);

  const { data: tasksInEachDay, status: taskStatus } = useQuery(
    ["ScheduleToken".firstday, lastday],
    () =>
      fetchSchedule(
        firstday.toISOString().split("T")[0],
        lastday.toISOString().split("T")[0]
      ),
    {
      onError: () => {
        navigate("/error500");
      },
    }
  );

  const { data: timeInEachDay, status: timeStatus } = useQuery(
    ["TimeToken", firstday, lastday],
    () =>
      fetchTotalHours(
        null,
        firstday.toISOString().split("T")[0],
        lastday.toISOString().split("T")[0]
      ),
    {
      onError: () => {
        navigate("/error500");
      },
    }
  );

  const { data: holidays, status: holidayStatus } = useQuery(
    ["holidayToken", firstday, lastday],
    () =>
      fetchHoliday(
        firstday.toISOString().split("T")[0],
        lastday.toISOString().split("T")[0]
      )
  );

  const { data: leaveDays, status: leaveDayStatus } = useQuery(
    ["leaveDayToken", firstday, lastday],
    () =>
      fetchEmployeeLeave(
        firstday.toISOString().split("T")[0],
        lastday.toISOString().split("T")[0]
      )
  );

  const { data: workFromOffices, status: workFromOfficesStatus } = useQuery(
    ["workFromOfficesToken", firstday, lastday],
    () => {
      return fetchWFOAttendance(
        firstday.toISOString().split("T")[0],
        lastday.toISOString().split("T")[0]
      );
    },
    {
      onError: () => {
        navigate("/error500");
      },
    }
  );
  const allStatus =
    taskStatus === "success" &&
    timeStatus === "success" &&
    holidayStatus === "success" &&
    leaveDayStatus === "success" &&
    workFromOfficesStatus === "success";
  const pushWeekDetails = (e, i) => {
    let hours = 0;
    let minutes = 0;
    let wfoHours = 0;
    let wfoMinutes = 0;
    const leaveHours = Number(
      leaveDays?.data?.[i].map((d) => calLeaveHour(d, i))
    );

    workFromOffices?.data?.[i].map((wfo) => {
      if (wfo.hours !== null && wfo.minutes !== null) {
        wfoHours += wfo.hours;
        wfoMinutes += wfo.minutes;
      }
    });
    if (mainProjectSelected.length != 0) {
      if (mainProjectSelected.includes("WFO")) {
        hours += wfoHours;
        minutes += wfoMinutes;
      }
      tasksInEachDay?.[i]?.map((task) => {
        if (mainProjectSelected.includes(task.project)) {
          hours = hours + task.hours;
          minutes = minutes + task.minutes;
        }
      });
      return {
        hours: hours + leaveHours + Math.floor(minutes / 60),
        minutes: minutes - 60 * Math.floor(minutes / 60),
      };
    }
    wfoHours += Math.floor(wfoMinutes / 60);
    wfoMinutes -= 60 * Math.floor(wfoMinutes / 60);
    return {
      hours:
        (timeInEachDay?.data?.find(
          (t) => new Date(Date.parse(t.date)).toLocaleDateString() === e
        )?.hourSum || 0) +
        wfoHours +
        leaveHours +
        hours,
      minutes:
        (timeInEachDay?.data?.find(
          (t) => new Date(Date.parse(t.date)).toLocaleDateString() === e
        )?.minuteSum || 0) + wfoMinutes,
    };
  };

  const calLeaveHour = (leaveDay, day_index) => {
    if (leaveDay.status == null || leaveDay.status == "Reject") {
      return 0;
    }
    if (
      (new Date(leaveDay.dateLeave).toDateString() ==
        new Date(listDate[day_index]).toDateString() &&
        (leaveDay.dateLeavePeriod == "Afternoon" ||
          leaveDay.dateLeavePeriod == "Morning")) ||
      (new Date(leaveDay.dateLeaveto) == new Date(listDate[day_index]) &&
        (leaveDay.dateLeaveToPeriod == "Afternoon" ||
          leaveDay.dateLeaveToPeriod == "Morning"))
    ) {
      return 4;
    }
    return 8;
  };

  if (timeStatus === "success") {
    listDate.map((e, i) => {
      weekDetails.push(pushWeekDetails(e, i));
    });
  }

  if (taskStatus === "success") {
    tasksInEachDay?.map((t) => {
      t.map((p) => {
        if (!projectColors.find((a) => a.project === p.project)) {
          projectColors.push({
            project: p.project,
            color: COLORS[projectColors.length],
          });
        }
      });
    });
  }

  return (
    <Stack direction="row" sx={{ width: 1, height: 1 }}>
      <Stack
        direction="column"
        sx={{
          height: 1,
          width: 0.15,
          minWidth: "fit-content",
          minHeight: "fit-content",
        }}
      >
        <Box sx={{ width: 1, height: 50, minHeight: 50 }}></Box>
        <DateCardsForWeek
          dateSelected={dateSelected}
          weekDetails={weekDetails}
          mainProjectSelected={mainProjectSelected}
          holidays={holidays}
          loadStatus={allStatus}
        />
      </Stack>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowX: "scroll",
          overflow: "overlay",
          height: 1,
          width: 1,
        }}
      >
        <Stack sx={{ width: 1, height: 50, minHeight: 50 }}>
          <TimeScale maxHour={24} />
        </Stack>
        {weekDetails?.map((day, day_index) => (
          <WeekDetailsWrapComponent 
            key={day_index}
            day_index={day_index}
            allStatus={allStatus}
            holidays={holidays}
            projectColors={projectColors}
            leaveDays={leaveDays}
            calLeaveHour={calLeaveHour}
            mainProjectSelected={mainProjectSelected}
            workFromOffices={workFromOffices}
            tasksInEachDay={tasksInEachDay}
          />
        ))}
      </Box>
    </Stack>
  );
}

function WeekDetailsWrapComponent({
  day_index,
  allStatus,
  holidays,
  projectColors,
  leaveDays,
  calLeaveHour,
  mainProjectSelected,
  workFromOffices,
  tasksInEachDay
}){

  return(
    <>
      <Box
        key={day_index}
        sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          ml: 1,
        }}
      >
        {allStatus ? (
          <>
            {holidays?.data?.[day_index]?.map(
              (holiday, holiday_index) => {
                return (
                  <ActivityCard
                    key={holiday_index}
                    index={holiday_index}
                    task={{
                      project: holiday.holidayName,
                      taskDetails: "",
                      hours: 8,
                      minutes: 0,
                    }}
                    projectColors={projectColors}
                    type={"holiday"}
                  />
                );
              }
            )}

            {leaveDays?.data?.[day_index]?.map(
              (leaveDay, leaveDay_index) => {
                return (
                  <ActivityCard
                    key={leaveDay_index}
                    index={leaveDay_index}
                    task={{
                      project: leaveDay.subject,
                      taskDetails: leaveDay.description,
                      type: LEAVETYPES[leaveDay.typeID - 1],
                      hours: calLeaveHour(leaveDay, day_index),
                      minutes: 0,
                    }}
                    projectColors={projectColors}
                    type={"leave"}
                  />
                );
              }
            )}

            {mainProjectSelected.length > 0
              ? tasksInEachDay?.[day_index]?.map((task, task_index) => {
                  if (
                    mainProjectSelected.find((p) => p === task.project)
                  ) {
                    return (
                      <ActivityCard
                        key={task_index}
                        index={task_index}
                        task={task}
                        projectColors={projectColors}
                        type={"activity"}
                      />
                    );
                  }
                  return <></>;
                })
              : tasksInEachDay?.[day_index]?.map((task, task_index) => {
                  return (
                    <ActivityCard
                      key={task_index}
                      index={task_index}
                      task={task}
                      projectColors={projectColors}
                      type={"activity"}
                    />
                  );
                })}

            {(mainProjectSelected.find((p) => p === "WFO") ||
              mainProjectSelected.length === 0) &&
              workFromOffices?.data?.[day_index]?.map(
                (wfo) =>
                  wfo.checkOut !== null && (
                    <ActivityCard
                      key={wfo.count}
                      index={wfo.count}
                      task={{
                        project: "WFO",
                        taskDetails: "",
                        checkIn: wfo.checkIn,
                        checkOut: wfo.checkOut,
                        hours: wfo.hours,
                        minutes: wfo.minutes,
                      }}
                      projectColors={projectColors}
                      type={"WFO"}
                    />
                  )
              )}
          </>
        ) : (
          <CircularProgress sx={{ m: "auto" }} />
        )}
      </Box>
      <Divider sx={{ position: "sticky", bottom: 0, left: 0 }} />
    </>
  );
};
