import React, { useState } from "react";

import {
  useTheme,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  LabelAndInputDate,
  LabelAndInputTaskDetails,
  LabelAndInputTime,
  ProjectSelector,
  StatusSelector,
} from "./EditModalComponents";

import { useMutation, useQueryClient } from "react-query";
import { fetchUpdateActivity } from "../../fetch/fecthActivity";
import { sanitizeHtmlContent } from "../../utils/stringHelper";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";

export default function EditModal({
  openEditModal,
  handleCloseEditModal,
  taskInputValue,
  statusList,
  projects,
  handleRefreshData,
}) {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const [taskDetails, setTaskDetails] = useState(taskInputValue.detail);
  const [taskDate, setTaskDate] = useState(new Date(taskInputValue.date));
  const [taskStatus, setTaskStatus] = useState(taskInputValue.status);
  const [taskProject, setTaskProject] = useState({
    label: taskInputValue.projectName,
    id: taskInputValue.projectId,
  });
  const [taskStartTime, setTaskStartTime] = useState(taskInputValue.startTime);
  const [taskEndTime, setTaskEndTime] = useState(taskInputValue.endTime);
  const [taskHours, setTaskHours] = useState(taskInputValue.hours);
  const [taskMinutes, setTaskMinutes] = useState(taskInputValue.minutes);
  const [workHoursOnly, setWorkHourOnly] = useState(taskStartTime == null);
  const [loading, setLoading] = useState(false);

  const { mutate } = useMutation(fetchUpdateActivity, {
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries(["activities"]);
      queryClient.invalidateQueries([
        "totalWorkHours",
        new Date().toDateString(),
      ]);
    },
    onSuccess: () => {
      setLoading(false);
      handleRefreshData();
      handleCloseEditModal();
      toast.success("Activity is edited successfully.");
    },
  });

  const updateTask = () => {
    if (validate()) {
      setLoading(true);
      mutate({
        ActivityId: taskInputValue.activityId,
        Hours:
          taskHours === undefined || taskHours === null || taskHours === ""
            ? 0
            : Number.parseInt(taskHours),
        Detail: sanitizeHtmlContent(taskDetails),
        WorkFromHome: taskInputValue.workFromHome,
        EmployeeId: taskInputValue.employeeId,
        ProjectId: taskProject.id,
        ProjectName: taskProject.label,
        Date: new Date(taskDate.setDate(taskDate.getDate() + 1)),
        Minutes:
          taskMinutes === undefined ||
          taskMinutes === null ||
          taskMinutes === ""
            ? 0
            : Number.parseInt(taskMinutes),
        StartTime: taskStartTime,
        EndTime: taskEndTime,
        Status: taskStatus,
      });
    }
  };

  const validate = () => {
    let rules = {};
    if (taskDetails == "") {
      rules.detail = "Details are required.";
      toast.error("Details are required.");
    } else rules.detail = "";

    if (taskDate.getFullYear() > 1970) rules.date = "";
    else if (taskDate == "Invalid Date") {
      rules.date = "Invalid Date.";
      toast.error("Invalid Date.");
    } else {
      rules.date = "Date is required.";
      toast.error("Date is required.");
    }

    if (!workHoursOnly) rules.workHours = "";
    else if (
      taskHours == 0 &&
      taskMinutes == 0 &&
      taskInputValue.workFromHome
    ) {
      rules.workHours = "You have to work more than 1 minutes.";
      toast.error("You have to work more than 1 minutes.");
    } else {
      rules.workHours = "";
    }

    if (workHoursOnly) rules.startTime = "";
    else if (taskStartTime == "") {
      rules.startTime = "Start time is required.";
      toast.error("Start time is required.");
    } else if (taskStartTime == "Invalid.") {
      rules.startTime = "Start time is invalid.";
      toast.error("Start time is invalid.");
    } else {
      rules.startTime = "";
    }

    if (workHoursOnly) rules.endTime = "";
    else if (taskEndTime == "") {
      rules.endTime = "End time is required.";
      toast.error("End time is required.");
    } else if (taskEndTime == "Invalid.") {
      rules.endTime = "End time is invalid.";
      toast.error("End time is invalid.");
    } else {
      rules.endTime = "";
    }
    if (taskStatus) rules.status = "";
    else {
      rules.status = "Status is required.";
      toast.error("Status is required.");
    }
    if (taskProject) rules.project = "";
    else {
      rules.project = "Project is required.";
      toast.error("Project is required.");
    }
    return Object.values(rules).every((x) => x == "");
  };

  const resetActivity = () => {
    setTaskDetails(taskInputValue.detail);
    setTaskDate(new Date(taskInputValue.date));
    setTaskStatus(taskInputValue.status);
    setTaskProject({
      label: taskInputValue.projectName,
      id: taskInputValue.projectId,
    });
    setTaskStartTime(taskInputValue.startTime);
    setTaskEndTime(taskInputValue.endTime);
    setTaskHours(taskInputValue.hours);
    setTaskMinutes(taskInputValue.minutes);
  };

  const resetStartTimeEndTime = () => {
    setTaskStartTime(taskInputValue.startTime);
    setTaskEndTime(taskInputValue.endTime);
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={openEditModal}
      onClose={handleCloseEditModal}
      disableEnforceFocus={true}
    >
      <DialogTitle
        color={theme.palette.custom.defaultTextColor}
        fontSize={20}
        fontWeight={600}
      >
        {"Edit Activity"}
        <IconButton
          aria-label="close"
          onClick={handleCloseEditModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Container maxWidth="md" disableGutters>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={2}>
              <Typography fontWeight={500} textAlign={"right"}>
                Project
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <ProjectSelector
                taskProject={taskProject}
                setTaskProject={setTaskProject}
                projects={projects}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography fontWeight={500} textAlign={"right"}>
                Details
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <LabelAndInputTaskDetails
                taskDetails={taskDetails}
                setTaskDetails={setTaskDetails}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography fontWeight={500} textAlign={"right"}>
                Status
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <StatusSelector
                taskStatus={taskStatus}
                setTaskStatus={setTaskStatus}
                statusList={statusList}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography fontWeight={500} textAlign={"right"}>
                Date
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <LabelAndInputDate
                taskDate={taskDate}
                setTaskDate={setTaskDate}
              />
            </Grid>
            <LabelAndInputTime
              taskStartTime={taskStartTime}
              setTaskStartTime={setTaskStartTime}
              taskEndTime={taskEndTime}
              setTaskEndTime={setTaskEndTime}
              taskHours={taskHours}
              setTaskHours={setTaskHours}
              taskMinutes={taskMinutes}
              setTaskMinutes={setTaskMinutes}
              workHoursOnly={workHoursOnly}
              setWorkHourOnly={setWorkHourOnly}
              resetStartTimeEndTime={resetStartTimeEndTime}
              isWorkFromHome={taskInputValue.workFromHome}
            />
            {taskInputValue.isSynced ? (
              <Typography sx={{ mt: 2, color: "red", fontSize: "12px" }}>
                Note: This WFO item was sent in the daily email. Also, the
                worklog update will not resend again.
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetActivity} variant="contained" color="inherit">
          Discard changes
        </Button>
        <LoadingButton
          loading={loading}
          onClick={() => updateTask()}
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.custom.lightPrimary,
            },
          }}
          variant="contained"
          color="confirmBtn"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
