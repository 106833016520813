import React from "react";
import Page404Component from "../components/ResponsePageComponent/Page404Component";

export default function NotFound() {
  return (
    <>
      <Page404Component />
    </>
  );
}
