import React, { useEffect } from "react";

import { Box, Typography, Button, useTheme } from "@mui/material";

import useTimer from "../../../hooks/useTimer";
import { getTime } from "../../../utils/time";

export default function Stopwatch({
  checkSw,
  setCheckSw,
}) {
  const theme = useTheme();

  const { timer, setTimer, isActive, handleReset, handleResume } = useTimer(0);

  const formatTime = (timer) => {
    let [hour, minute, second] = getTime(timer);
    return `${hour} : ${minute} : ${second}`;
  };
  
  useEffect(() => {
    if (checkSw) {
      let now = new Date();
      let startTime = localStorage.getItem("StartTime")
        ? parseInt(localStorage.getItem("StartTime"))
        : 0;
      let time = 0;
      let timeNow =
        now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();

      if (timeNow < startTime) {
        time = timeNow + 24 * 3600 - startTime;
      } else {
        time = timeNow - startTime;
      }

      setTimer(time);
      handleResume();
    } 
    else {
      handleReset();
      localStorage.removeItem("StartTime");
      localStorage.removeItem("StartDate");
      localStorage.removeItem("value");
    }
  }, [checkSw]);

  const onClickStart = () => {
    setCheckSw(true);
    let now = new Date();
    let time = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
    localStorage.setItem("StartTime", time);
    localStorage.setItem("StartDate", now);
  };

  const onClickReset = () => {
    setCheckSw(false);
    handleReset();
    localStorage.removeItem("StartTime");
    localStorage.removeItem("StartDate");
    localStorage.removeItem("value");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h1" component="h2">
        {formatTime(timer)}
      </Typography>

      <Box>
        <Button
          sx={{ color: theme.palette.custom.white }}
          disabled={isActive}
          onClick={onClickStart}
        >
          Start
        </Button>
        <Button
          disabled={!isActive}
          sx={{ color: theme.palette.custom.red }}
          onClick={onClickReset}
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
}
