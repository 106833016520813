import React, { useState } from "react";

import {
  useTheme,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import { LabelAndInputTaskDetails } from "./EditModalComponents";
import { useMutation } from "react-query";
import { updateWFOActivity } from "../../fetch/fetchTimeAttendance";
import toast from "react-hot-toast";
import moment from "moment";
import { LoadingButton } from "@mui/lab";

export default function WFOEditModal({
  wfoSelected,
  openWFOEditModal,
  handleCloseWFOEditModal,
  handleRefreshData,
}) {
  const theme = useTheme();
  const [wfoDetails, setTaskDetails] = useState(wfoSelected?.inOutDetail);
  const [loading, setLoading] = useState(false);

  const { mutate } = useMutation(updateWFOActivity, {
    onError: () => {},
    onSettled: () => {},
    onSuccess: () => {
      setTimeout(() => {
        handleRefreshData();
        handleCloseWFOEditModal();
        toast.success("WFO Activity is updated successfully.");
        setLoading(false);
      }, 500);
    },
  });

  const updateWFOTicket = () => {
    if (validate()) {
      setLoading(true);
      mutate({
        Count: wfoSelected.count,
        EmployeeId: wfoSelected.employeeId,
        InOutDetail: wfoDetails,
      });
    }
  };

  const validate = () => {
    if (wfoDetails == "") {
      toast.error("Details are required.");
      return false;
    }

    return true;
  };

  const resetWFOActivity = () => {
    setTaskDetails(wfoSelected?.inOutDetail);
  };

  const calculateTotalWorkHours = () => {
    if (wfoSelected && wfoSelected.hours) {
      return `${wfoSelected?.hours} hrs`;
    } else {
      return " -- hrs -- mins";
    }
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={openWFOEditModal}
      onClose={handleCloseWFOEditModal}
      disableEnforceFocus={true}
    >
      <DialogTitle
        color={theme.palette.custom.defaultTextColor}
        fontSize={20}
        fontWeight={600}
      >
        {"Edit WFO Activity"}
        <IconButton
          aria-label="close"
          onClick={handleCloseWFOEditModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Container maxWidth="md" disableGutters>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={2}>
              <Typography fontWeight={500} textAlign={"right"}>
                Details
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <LabelAndInputTaskDetails
                taskDetails={wfoDetails}
                setTaskDetails={setTaskDetails}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography fontWeight={500} textAlign={"right"}>
                Date
              </Typography>
            </Grid>
            <Grid item xs={10}>
              {moment(wfoSelected?.checkIn).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={2}>
              <Typography fontWeight={500} textAlign={"right"}>
                Time
              </Typography>
            </Grid>
            <Grid item xs={10}>
              {moment(wfoSelected?.checkIn).format("HH:mm")} -{" "}
              {wfoSelected?.checkOut
                ? moment(wfoSelected?.checkOut).format("HH:mm")
                : "No clock out time"}
            </Grid>
            <Grid item xs={2}>
              <Typography fontWeight={500} textAlign={"right"}>
                Work Totals
              </Typography>
            </Grid>
            <Grid item xs={10}>
              {calculateTotalWorkHours()}
            </Grid>
            <Grid item xs={2}>
              <Typography fontWeight={500} textAlign={"right"}>
                Include Break
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Checkbox checked={wfoSelected?.isIncludeBreak} disabled />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
              {wfoSelected?.isSentEmail ? (
                <Typography sx={{ color: "red", fontSize: "12px" }}>
                  Note: This WFO item was sent in the daily email. Also, the
                  worklog update will not resend again.
                </Typography>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={resetWFOActivity}>
          Discard changes
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="confirmBtn"
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.custom.lightPrimary,
            },
          }}
          onClick={() => updateWFOTicket()}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
