import { Grid, Box, useTheme } from "@mui/material";

export default function DayName({ dayName }) {
  const theme = useTheme();
  return (
    <>
      <Grid item xs={1}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.2rem",
            fontWeight: "500",
            color: theme.palette.custom.primary,
            padding: "0.5rem 0",
          }}
        >
          {dayName}
        </Box>
      </Grid>
    </>
  );
}
