import React from "react";

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { TeamMember } from "./ProjectDetailComponents";

import useWindowSize from "../../hooks/useWindowSize";

export default function ProjectDetail({ projectDetail }) {
  const windowSize = useWindowSize();

  return (
    <Box m={4}>
      <Typography
        sx={{
          fontSize:
            windowSize.width > 1100 ? 20 : windowSize.width > 720 ? 18 : 16,
          fontWeight: 500,
        }}
      >
        Project Detail
      </Typography>
      {projectDetail ? (
        <>
          <Table
            key={projectDetail.projectId}
            sx={{
              cursor: "default",
              fontSize:
                windowSize.width <= 720
                  ? 12
                  : windowSize.width <= 1100
                  ? 14
                  : 16,
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell>
                  <Box>{projectDetail.projectName}</Box>
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Description</TableCell>
                <TableCell>
                  {projectDetail.description ? projectDetail.description : "-"}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Members</TableCell>
                <TableCell>
                  <TeamMember projectId={projectDetail.projectId} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
    </Box>
  );
}
