import React, { useState, useEffect } from "react";

import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
  Box,
  useTheme,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";

import {
  DateTimeInput,
  HourInput,
  InputTaskDescription,
  MinuteInput,
  ProjectSelector,
  Stopwatch,
  StatusSelector,
  WorkDateInput,
} from "./AddActivityComponents";

import useWindowSize from "../../hooks/useWindowSize";
import { useStyles } from "../../hooks/useStyles";
import { useQuery, useMutation, useQueryClient } from "react-query";

import {
  fetchInsertActivity,
  fetchEmployeeID,
} from "../../fetch/fecthActivity";
import {
  fetchInsertActivityLunchBreak,
  fetchActivityLunchBreak,
} from "../../fetch/fecthActivityLunchBreak";

import toast from "react-hot-toast";
import { Errors } from "@azure/msal-common/dist/utils/Constants";
import { getTime, getTimeNow } from "../../utils/time";
import { sanitizeHtmlContent } from "../../utils/stringHelper";
import { withStyles } from "@material-ui/core/styles";
import { LoadingButton } from "@mui/lab";

const AntSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.common.white,
    "&$checked": {
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#E75050",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  track: {
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function AddActivity({
  projects,
  statusList,
  handleRefreshData,
}) {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const windowSize = useWindowSize();
  const classes = useStyles();

  const [currentSelectedDate, SetCurrentSelectedDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [project, setProject] = useState(projects[0]);
  const [status, setStatus] = useState(statusList[0]);
  const [hours, setHours] = useState(0);
  const [inputHours, setInputHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [inputMinutes, setInputMinutes] = useState(0);
  const [inputType, setInputType] = useState(0);
  const [checkSw, setCheckSw] = useState(false);
  const [detail, setDetail] = useState("");
  const [error, setError] = useState({
    detail: false,
    hoursMin: false,
    startEndTime: false,
  });
  const [isWFO, setIsWFO] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data: empID } = useQuery("employeeID", () => fetchEmployeeID());
  const { mutate } = useMutation(fetchInsertActivity, {
    onSuccess: (data) => {
      if (data.status === 400) {
        toast.error("You didn't work from office this date.");
      } else {
        toast.success("Activity is added successfully.");
        clearInputValue();
      }
      setLoading(false);
      handleRefreshData();
    },
    onError: () => {},
    onSettled: (data) => {
      queryClient.invalidateQueries([
        "activities",
        new Date(data.data.date).toDateString(),
      ]);
      queryClient.invalidateQueries([
        "totalWorkHours",
        new Date().toDateString(),
      ]);
    },
  });

  const { mutate: mutateLunchBreak } = useMutation(
    fetchInsertActivityLunchBreak,
    {
      onSuccess: (data) => {
        if (data.status === 400) {
          toast.error("You didn't work from office this date.");
        } else {
          toast.success("Activity is added successfully.");
          clearInputValue();
        }
        handleRefreshData();
      },
      onError: () => {},
      onSettled: (data) => {
        queryClient.invalidateQueries([
          "activitiesLunchBreak",
          new Date(data.date).toDateString(),
        ]);
      },
    }
  );

  useEffect(() => {
    if (localStorage.getItem("StartTime")) {
      let value = JSON.parse(localStorage.getItem("value"));
      setInputType(1);
      setCheckSw(true);
      setProject(value?.p);
      if (value?.s !== undefined) setStatus(value?.s);
      else setStatus(statusList[0]);
      setDetail(value?.d);
    }
  }, []);

  useEffect(() => {
    if (checkSw) {
      let valueStatus = statusList[0];
      if (status !== undefined) valueStatus = status;
      var value = { p: project, s: valueStatus, d: detail, st: startTime };
      localStorage.setItem("value", JSON.stringify(value));
    }
  }, [checkSw]);

  useEffect(() => {
    if (localStorage.getItem("StartTime")) {
      if (project) {
        let valueStatus = statusList[0];
        if (status !== undefined) valueStatus = status;
        var value = { p: project, s: valueStatus, d: detail, st: startTime };
        localStorage.setItem("value", JSON.stringify(value));
      }
    }
  }, [project, status, detail]);

  useEffect(() => {
    if (projects) setProject(projects[0]);
  }, [projects]);

  useEffect(() => {
    if (detail) setError({ ...error, detail: false });
  }, [detail]);

  useEffect(() => {
    if ((inputHours != 0 && inputMinutes != 0) || !isInvalidInputTime())
      setError({ ...error, hoursMin: false });
  }, [inputHours, inputMinutes]);

  useEffect(() => {
    if (
      error.startEndTime &&
      startTime != null &&
      endTime != null &&
      startTime < endTime
    )
      setError({ ...error, startEndTime: false });
  }, [startTime, endTime]);

  const changeTime = (value) => {
    const myArray = value.toString().split(" ");
    let word = myArray[4];

    return word.substring(0, 5);
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const tranDate = (value) => {
    return [
      value.getFullYear(),
      padTo2Digits(value.getMonth() + 1),
      padTo2Digits(value.getDate()),
    ].join("-");
  };

  const calTime = (stime, etime) => {
    let m;
    let h;
    const st = stime.toString().split(":");
    const et = etime.toString().split(":");

    if (Number(et[1]) < Number(st[1])) {
      m = 60 + Number(et[1]) - Number(st[1]);
      h = Number(et[0] - 1);
      h = h - Number(st[0]);
      return [h, m];
    }

    m = Number(et[1]) - Number(st[1]);
    h = Number(et[0]) - Number(st[0]);

    return [h, m];
  };

  const isInvalidInputTime = () => {
    return (
      hours > 23 ||
      hours < 0 ||
      minutes > 59 ||
      minutes < 0 ||
      !!(hours % 1) ||
      !!(minutes % 1)
    );
  };

  const clearInputValue = () => {
    setProject(projects[0]);
    setStatus(statusList[0]);
    setDetail("");
    setInputHours(0);
    setInputMinutes(0);
  };

  const checkIncludeBreak = (date) => {
    fetchActivityLunchBreak(date).then(function (result) {
      var ch = result;
      if (!ch[0]) {
        mutateLunchBreak({
          ActivityLunchBreakId: 0,
          EmployeeId: empID.e,
          Date: date,
          IsIncludeBreak: false,
        });
      }
    });
  };

  const handleWorkDateChange = (date) => {
    var nowDate = Date.now();
    if (date <= nowDate) {
      SetCurrentSelectedDate(date);
    }
  };

  const clearAllErrorStates = () => {
    setError({ 
      detail: false,
      hoursMin: false,
      startEndTime: false
    });
  }

  const addActivity = () => {
    setLoading(true);
    if (detail == "") {
      setError({ ...error, detail: true });
      setLoading(false);
      return toast.error("The project detail is empty");
    }

    let date = tranDate(currentSelectedDate);

    let Task = {
      ActivityId: 0,
      Hours: 0,
      Detail: sanitizeHtmlContent(detail),
      WorkFromHome: !isWFO,
      EmployeeId: empID.e,
      ProjectId: project.id,
      Date: date,
      Minutes: 0,
      StartTime: "00:00",
      EndTime: "00:00",
      Status: status,
    };
    let taskSplit = {
      ActivityId: 0,
      Hours: 0,
      Detail: sanitizeHtmlContent(detail),
      WorkFromHome: !isWFO,
      EmployeeId: empID.e,
      ProjectId: project.id,
      Date: date,
      Minutes: 0,
      StartTime: "00:00",
      EndTime: "00:00",
      Status: status,
    };
    if (!detail) return toast.error("Details is required.");
    switch (inputType) {
      case 0:
        if (isInvalidInputTime()) {
          setError({ ...error, hoursMin: true });
          setLoading(false);

          return toast.error("Time is invalid.");
        }

        //check time
        if (!isWFO) {
          if (inputHours == 0 && inputMinutes == 0) {
            setError({ ...error, hoursMin: true });
            setLoading(false);

            return toast.error("You should work more than 1 minutes.");
          }
        }

        checkIncludeBreak(date);
        mutate({
          ActivityId: 0,
          Hours: inputHours,
          Detail: sanitizeHtmlContent(detail),
          WorkFromHome: !isWFO,
          EmployeeId: empID.e,
          ProjectId: project.id,
          Date: date,
          Minutes: inputMinutes,
          StartTime: null,
          EndTime: null,
          Status: status,
        });
        clearAllErrorStates();
        return;
      case 1:
        let newTime = getTimeNow();
        let [h, m] = getTime(newTime);
        setHours(h);
        setMinutes(m);

        if (Math.floor(h) === 0 && Math.floor(m) < 1 && !isWFO) {
          setLoading(false);

          return toast.error("You should work more than 1 minutes.");
        }
        let st = localStorage.getItem("StartDate");
        let et = new Date();

        if (st) {
          let sTime1 = changeTime(st);
          let eTime1 = changeTime(et);
          if (eTime1 < sTime1) {
            //let arriT1_1 = calTime(sTime1, "24:00");
            //Task.Hours = arriT1_1[0];
            Task.Date = tranDate(localStorage.getItem("StartDate"));
            //Task.Minutes = arriT1_1[1];
            Task.StartTime = sTime1;
            checkIncludeBreak(tranDate(localStorage.getItem("StartDate")));
            setCheckSw(false);

            mutate(Task);
            if (eTime1 != "00:00") {
              //let arriT1_2 = calTime("00:00", eTime1);
              //taskSplit.Hours = arriT1_2[0];
              taskSplit.Date = tranDate(et);
              //taskSplit.Minutes = arriT1_2[1];
              taskSplit.StartTime = "00:00";
              taskSplit.EndTime = eTime1;
              checkIncludeBreak(tranDate(et));
              mutate(taskSplit);
              clearAllErrorStates();
            }
            return;
          }
          //Task.Hours = hours;
          Task.Date = tranDate(et);
          //Task.Minutes = minutes;
          Task.StartTime = sTime1;
          Task.EndTime = eTime1;
          checkIncludeBreak(tranDate(et));
          mutate(Task);
          setCheckSw(false);
          clearAllErrorStates();
          return;
        }
        else {
          setLoading(false);
          return toast.error("The timer for the worklog hasn't been started.");
        }
      default:
        if (startTime == null || endTime == null) {
          setError({ ...Errors, startEndTime: true });
          setLoading(false);

          return toast.error("Start time or End time is invalid.");
        }
        console.log(startTime, endTime);
        let sTime = changeTime(startTime);
        let eTime = changeTime(endTime);
        if (endTime <= startTime) {
          setError({ ...Errors, startEndTime: true });
          setLoading(false);

          return toast.error("Start time should be before End time.");
        }
        if (eTime < sTime) {
          let arr1 = calTime(sTime, "23:59");
          Task.Hours = arr1[0];
          Task.Date = tranDate(startTime);
          Task.Minutes = arr1[1];
          Task.StartTime = sTime;
          Task.EndTime = "23:59";
          checkIncludeBreak(tranDate(startTime));
          mutate(Task);

          if (eTime != "00:00") {
            let arr2 = calTime("00:00", eTime);
            taskSplit.Hours = arr2[0];
            taskSplit.Date = tranDate(endTime);
            taskSplit.Minutes = arr2[1];
            taskSplit.EndTime = eTime;
            checkIncludeBreak(tranDate(endTime));
            mutate(taskSplit);
          }

          clearAllErrorStates();
          return;
        }
        if (tranDate(endTime) == tranDate(startTime)) {
          let arr = calTime(sTime, eTime);
          Task.Hours = arr[0];
          Task.Date = tranDate(startTime);
          Task.Minutes = arr[1];
          Task.StartTime = sTime;
          Task.EndTime = eTime;
          checkIncludeBreak(tranDate(startTime));
          mutate(Task);

          clearAllErrorStates();
          return;
        }
        setError({ ...Errors, startEndTime: true });
        setLoading(false);

        return toast.error("Can't work more than 24 hours in one day.");
    }
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        width: 0.95,
        whiteSpace: "nowrap",
        color: theme.palette.custom.white,
        backgroundColor: theme.palette.custom.primary,
        alignItems: "center",
        borderRadius: "5px",
        p: "20px 15px",
        mx: "auto",
      }}
    >
      <Stack
        direction={windowSize.width >= 750 ? "row" : "column"}
        spacing={windowSize.width >= 750 ? 0 : 2}
        sx={{ width: 1 }}
      >
        <Stack
          direction="column"
          spacing={1.5}
          sx={{ mr: 2, alignItems: "center" }}
        >
          <WorkDateInput
            inputModeType={inputType}
            currentSelectedDate={currentSelectedDate}
            handleWorkDateChange={handleWorkDateChange}
          />
          <ProjectSelector
            setProject={setProject}
            projects={projects}
            project={project}
            width={windowSize >= 750 ? 200 : 1}
          />
          <StatusSelector
            statusList={statusList}
            status={status}
            setStatus={setStatus}
            width={windowSize >= 750 ? 200 : 1}
          />
        </Stack>
        {(windowSize.width >= 900 || windowSize.width <= 750) && (
          <Stack
            spacing="10px"
            sx={{
              flex: 1,
              overflow: "hidden",
              width: 1,
              alignItems: "center",
              mr: "20px",
            }}
          >
            <InputTaskDescription
              detail={detail}
              setDetail={setDetail}
              //isError={error.detail}
              classes={classes}
            />
          </Stack>
        )}
        <Stack
          spacing="15px"
          sx={{
            ml: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            direction="row"
            spacing="15px"
            sx={{
              ml: "auto",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {inputType === 0 && (
              <>
                <HourInput
                  hours={inputHours}
                  setHours={setInputHours}
                  isError={error.hoursMin}
                />
                <MinuteInput
                  minutes={inputMinutes}
                  setMinutes={setInputMinutes}
                  isError={error.hoursMin}
                />
              </>
            )}

            {inputType === 1 && (
              <>
                <Stopwatch checkSw={checkSw} setCheckSw={setCheckSw} />
                <LoadingButton
                  loading={loading}
                  onClick={() => {
                    addActivity();
                  }}
                  variant="contained"
                  color="addBtn"
                  sx={{
                    ":hover": {
                      bgcolor: "red",
                    },
                  }}
                >
                  ADD
                </LoadingButton>
              </>
            )}

            {inputType === 2 && (
              <Stack spacing={1.5}>
                <DateTimeInput
                  label="start"
                  setValue={setStartTime}
                  value={startTime}
                  isStartTime={true}
                  isError={error.startEndTime}
                />
                <DateTimeInput
                  label="end"
                  setValue={setEndTime}
                  value={endTime}
                  isStartTime={false}
                  startTime={startTime}
                  isError={error.startEndTime}
                />
              </Stack>
            )}

            {inputType !== 1 && (
              <LoadingButton
                loading={loading}
                onClick={() => {
                  addActivity();
                }}
                variant="contained"
                color="addBtn"
                sx={{
                  ":hover": {
                    bgcolor: "red",
                  },
                }}
              >
                ADD
              </LoadingButton>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <AccessTimeOutlinedIcon
                color={
                  inputType === 0 ? theme.palette.custom.white : "disabled"
                }
                onClick={() => {
                  setInputType(0);
                }}
                style={{
                  cursor: "pointer",
                }}
              />
              <TimerOutlinedIcon
                color={
                  inputType === 1 ? theme.palette.custom.white : "disabled"
                }
                onClick={() => setInputType(1)}
                style={{
                  cursor: "pointer",
                }}
              />
              <MenuOutlinedIcon
                color={
                  inputType === 2 ? theme.palette.custom.white : "disabled"
                }
                onClick={() => setInputType(2)}
                style={{
                  cursor: "pointer",
                }}
              />
            </Box>
          </Stack>
          <FormControlLabel
            control={
              <AntSwitch
                checked={isWFO}
                onChange={(e) => {
                  setIsWFO(e.target.checked);
                }}
                name="WFOCheck"
              />
            }
            labelPlacement="start"
            label="Work from office"
          />
        </Stack>
      </Stack>
      {windowSize.width < 900 && windowSize.width > 750 && (
        <Stack
          spacing="10px"
          sx={{
            flex: 1,
            width: 1,
            alignItems: "center",
            mr: "20px",
          }}
        >
          <InputTaskDescription detail={detail} setDetail={setDetail} />
        </Stack>
      )}
    </Stack>
  );
}
