const colorsForCard = [
  "#d0e5e7",
  "#E7FFAC",
  "#F8EE9A",
  "#fff1b8",
  "#FFD3E8",
  "#eebdf4",
  "#E8D7FF",
  "#9d9bea",
];

const statusColors = {
  inProcess: "#5185EC",
  pending: "#F1BE42",
  done: "#57C302",
};

const textCardColor = "#363062";
const defaultColorForTooManyProject = "#aeabb7";
const leaveCardColor = "#C4DDFF";
const holidayCardColor = "#B6FFCE";
const wfoCardColor = "#ffb8b8";

export {
  colorsForCard,
  statusColors,
  textCardColor,
  defaultColorForTooManyProject,
  leaveCardColor,
  holidayCardColor,
  wfoCardColor
};
