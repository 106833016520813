import React from "react";
import { Box, Typography, Tooltip, useTheme } from "@mui/material";
import useWindowSize from "../../../hooks/useWindowSize";

import {
  defaultColorForTooManyProject,
  holidayCardColor,
  leaveCardColor,
  textCardColor,
  wfoCardColor,
} from "../../../utils/color";

import { sanitizeNoHtmlTagContent } from "../../../utils/stringHelper";

export default function ActivityCard({ task, projectColors, type }) {
  const theme = useTheme();

  const windowSize = useWindowSize();
  const taskInfo = () => {
    if (type === "activity")
      return `Project: ${task.project}\nTaskDetails: ${sanitizeNoHtmlTagContent(task.taskDetails)}\nHours: ${task.hours}\nMinutes: ${task.minutes}`;
    if (type === "WFO")
      return `CheckIn: ${new Date(
        task.checkIn
      ).toLocaleTimeString()}\nCheckOut: ${new Date(
        task.checkOut
      ).toLocaleTimeString()}\nHours: ${task.hours}\nMinutes: ${task.minutes}`;
    if (type === "holiday") return `${task.project}`;
    return `Subject: ${task.project}\nDescription: ${sanitizeNoHtmlTagContent(task.taskDetails)}\nType: ${task.type}\nHours: ${task.hours}\n`;
  };

  const chooseColor = (type) => {
    switch (type) {
      case "activity":
        return (
          projectColors?.find((p) => p.project === task.project)?.color ||
          defaultColorForTooManyProject
        );
      case "holiday":
        return holidayCardColor;
      case "leave":
        return leaveCardColor;
      case "WFO":
        return wfoCardColor;
    }
    return (
      projectColors?.find((p) => p.project === task.project)?.color ||
      defaultColorForTooManyProject
    );
  };

  return (
    <Tooltip
      title={<span style={{ whiteSpace: "pre-line" }}>{taskInfo()}</span>}
      placement="right"
      followCursor
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          color: textCardColor,
          backgroundColor: chooseColor(type),
          height: 0.9,
          my: "auto",
          borderRadius: "5px",
          border: theme.palette.custom.white + " solid 1px",
          alignItems: "center",
          width:
            windowSize.width >= 700
              ? `${0.1 * (task.hours + task.minutes / 60) * 100}%`
              : `${0.18 * (task.hours + task.minutes / 60) * 100}%`,
          minWidth:
            windowSize.width >= 700
              ? `${0.1 * (task.hours + task.minutes / 60) * 100}%`
              : `${0.18 * (task.hours + task.minutes / 60) * 100}%`,
          cursor: "default",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            my: "auto",
            width: 1,
          }}
        >
          <Box
            sx={{
              width: 0.9,
              textAlign: "center",
            }}
          >
            <Typography
              align="center"
              noWrap={true}
              sx={{
                fontWeight: 700,
              }}
            >
              {task.project}
            </Typography>
          </Box>

          <Box
            sx={{
              width: 0.9,
              textAlign: "center",
            }}
          >
            <Typography
              align="center"
              noWrap={true}
              sx={{
                fontWeight: 500,
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizeNoHtmlTagContent(task.taskDetails),
                }}
              ></span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Tooltip>
  );
}
