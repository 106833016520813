import React, { useState } from "react";

import { Button, useTheme, Stack } from "@mui/material";

import { WeekPicker, ProjectsSelector } from "./ScheduleFilterComponents";

import useWindowSize from "../../hooks/useWindowSize";

export default function ScheduleFilter({
  projects,
  dateSelected,
  setDateSelected,
  dateSelectedChange,
  setMainProjectSelected,
}) {
  const theme = useTheme();
  const windowSize = useWindowSize();

  const [projectsSelected, setProjectsSelected] = useState([]);

  const projectsSelectedChange = (event) => {
    const {
      target: { value },
    } = event;
    setProjectsSelected(typeof value === "string" ? value.split(",") : value);
    setMainProjectSelected(
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: "center",
          m: 2,
          ml: "auto",
          width: "fit-content",
        }}
      >
        {windowSize.width >= 700 ? (
          <>
            <ProjectsSelector
              projectsSelected={projectsSelected}
              projectsSelectedChange={projectsSelectedChange}
              projects={projects}
              width={200}
            />
            <WeekPicker
              dateSelected={dateSelected}
              setDateSelected={setDateSelected}
              onChange={dateSelectedChange}
              width={200}
            />
          </>
        ) : (
          <Stack
            direction="column"
            spacing={1}
            sx={{
              alignItems: "end",
              width: "fit-content",
            }}
          >
            <ProjectsSelector
              projectsSelected={projectsSelected}
              projectsSelectedChange={projectsSelectedChange}
              projects={projects}
              width={windowSize.width >= 400 ? 200 : 150}
            />
            <WeekPicker
              dateSelected={dateSelected}
              setDateSelected={setDateSelected}
              onChange={dateSelectedChange}
              width={windowSize.width >= 400 ? 200 : 150}
            />
          </Stack>
        )}

        <Button
          variant="contained"
          onClick={() => dateSelectedChange(new Date())}
          sx={{
            color: theme.palette.custom.white,
            backgroundColor: theme.palette.custom.primary,
            "&:hover": {
              backgroundColor: theme.palette.custom.lightPrimary,
            },
          }}
        >
          Today
        </Button>
      </Stack>
    </>
  );
}
