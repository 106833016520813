import React from "react";

import { Navigate } from "react-router-dom";

import Page403Component from "./ResponsePageComponent/Page403Component";

export class Role {
  static Manager = new Role("Manager", 1);
  static Admin = new Role("Admin", 2);
  static Employee = new Role("Employee", 3);
  static Interviewer = new Role("Interviewer", 4);
  static Intern = new Role("Intern", 5);
  static HR = new Role("HR", 6);

  constructor(roleName, roleId) {
    this.roleName = roleName;
    this.roleId = roleId;
  }
}

export default function ProtectedRoute({ role, children }) {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user === null) {
    return <Navigate to="/login" />;
  }

  switch (role) {
    case "allowAllRoles":
      return children;
    case Role.Manager:
      if (user.roleId === Role.Manager.roleId) {
        return children;
      }
      return <Page403Component />;
    case Role.Admin:
      if (
        user.roleId === Role.Admin.roleId ||
        user.roleId === Role.Manager.roleId ||
        user.roleId === Role.HR.roleId
      ) {
        return children;
      }
      return <Page403Component />;
    case Role.Employee:
      if (
        user.roleId === Role.Employee.roleId ||
        user.roleId === Role.Manager.roleId ||
        user.roleId === Role.Admin.roleId ||
        user.roleId === Role.HR.roleId
      ) {
        return children;
      }
      return <Page403Component />;
    case Role.Interviewer:
      if (
        user.roleId === Role.Interviewer.roleId ||
        user.roleId === Role.Manager.roleId ||
        user.roleId === Role.Admin.roleId ||
        user.roleId === Role.HR.roleId
      ) {
        return children;
      }
      return <Page403Component />;
    case Role.Intern:
      if (
        user.roleId === Role.Intern.roleId ||
        user.roleId === Role.Employee.roleId ||
        user.roleId === Role.Manager.roleId ||
        user.roleId === Role.Admin.roleId ||
        user.roleId === Role.HR.roleId
      ) {
        return children;
      }
      return <Page403Component />;
    case Role.HR:
      if (
        user.roleId === Role.HR.roleId ||
        user.roleId === Role.Manager.roleId ||
        user.roleId === Role.Admin.roleId
      ) {
        return children;
      }
      return <Page403Component />;
    default:
      return <Page403Component />;
  }
}
