import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CKEditorConfig } from "../../../utils/ckEditorConfig";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../rte.css";

export default function LabelAndInputTaskDetails({
  taskDetails,
  setTaskDetails,
}) {
  return (
    <div className="custom-rte custom-rte-edit" style={{ overflowX: "hidden" }}>
      <CKEditor
        editor={ClassicEditor}
        config={CKEditorConfig}
        data={taskDetails}
        onChange={(event, editor) => {
          const data = editor.getData();
          setTaskDetails(data);
        }}
      />
    </div>
  );
}
