import { TextField, useTheme } from "@mui/material";

export default function HourInput({ hours, setHours, isError }) {
  const theme = useTheme();
  return (
    <TextField
      id="standard-number"
      label="Hours"
      type="number"
      value={hours}
      color="inputColor"
      onChange={(e) => {
        if (e.target.value > 23) e.target.value = 23;
        else if (e.target.value < 0) e.target.value = 0;
        setHours(e.target.value);
      }}
      InputProps={{ inputProps: { min: 0, max: 23 } }}
      inputlabelprops={{
        shrink: true,
        style: { color: theme.palette.custom.white },
        maxLength: 23,
        minLength: 0,
      }}
      variant="standard"
      sx={{
        width: 50,
        input: { color: "white", borderColor: "red" },
        color: theme.palette.custom.white,
        "& .MuiTextField-root": {
          border: "1px solid red",
        },
      }}
      size="small"
      error={isError}
    />
  );
}
