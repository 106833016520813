import React, { useEffect, useState } from "react";
import { Box, Typography, Collapse } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StatusWorkHoursBar from "./StatusWorkHoursBar";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import moment from "moment";
import { getHourMinString } from "../../utils/workhourHelper";

export default function TableRowDetail({
  wh,
  no,
  sumStandardH,
  sumWorkH,
  sumLeaveH,
  sumTotalH,
  changePage,
  setChangePage,
  setSelectedWork,
  handleOpenActivityDetailsModal
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setChangePage(false);
    setIsOpen(false);
  }, [setChangePage, setIsOpen, changePage]);

  const calHours = (timeInput) => {
    let time = timeInput.toFixed(2);
    let h = Number(String(time).split(".")[0]);
    let m = String(time).split(".")[1];

    if (timeInput > 0) {
      let num =
        Number(m) > 60
          ? h + 1 + (Number(m) % 60) / 100
          : h + (Number(m) % 60) / 100;
      return num.toFixed(2);
    } else {
      let num =
        Number(m) > 60
          ? (h - 1) * -1 + (Number(m) % 60) / 100
          : h * -1 + (Number(m) % 60) / 100;
      return num.toFixed(2);
    }
  };

  const calOverTime = (sd, wh) => {
    let sdH = sd;
    let sdM = 60;
    let whH = Number(String(wh).split(".")[0]);
    let whM =
      String(wh).split(".")[1] !== undefined
        ? Number(
            String(wh).split(".")[1].length === 1
              ? String(wh).split(".")[1] + 0
              : String(wh).split(".")[1]
          )
        : 0;

    let h = 0;
    let m = 0;
    let res = 0;

    // overtime
    if (whH >= sdH) {
      h = whH - sdH >= 0 ? whH - sdH : whH - sdH - 1;
      m = whM - 0;
      res = h + (m % 60) / 100;
    }
    // undertime
    else {
      // h =  whM >= 30 ? (whH - sdH) + 1: (whH - sdH);
      h = wh > 0 && whM > 0 ? whH - sdH + 1 : whH - sdH;
      m = whM - sdM;
      res =
        whH < sdH || h < 0
          ? (h * -1 + ((m * -1) % 60) / 100) * -1
          : h + ((m * -1) % 60) / 100;
    }
    return res.toFixed(2);
  };

  const handleActivityDetailClick = (work) => {
    setSelectedWork(work);
    handleOpenActivityDetailsModal();
  };

  const chooseOtherProjectColor = (wh, currentProjectId) => {
    //style other project
    if (wh) {
      if (wh.projectList.some(c => c.projectId === currentProjectId && !c.isMainProject)) {
        let color = wh.projectList.find(c => c.projectId === currentProjectId)?.projectColor;
        if (color !== undefined && color !== null && color?.length > 0) {
          return color;
        }
      }
      return "eeeeee";
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {no + 1}
        </TableCell>
        <TableCell align="center">
          {wh.projectList.map((pro, key) => {
            if (wh.projectList.length !== key + 1) {
              return pro.projectName + " , ";
            }
            return pro.projectName;
          })}
        </TableCell>
        <TableCell align="center">
          {wh.firstName} {wh.lastName}
        </TableCell>
        <TableCell align="center">{wh.nickName}</TableCell>
        <TableCell align="center">{getHourMinString(sumStandardH)}</TableCell>
        <TableCell align="center">{getHourMinString(Number(calHours(sumWorkH)))}</TableCell>
        <TableCell align="center">{getHourMinString(sumLeaveH)}</TableCell>
        <TableCell align="center">{getHourMinString(Number(calHours(sumTotalH)))}</TableCell>
        <TableCell align="center">
          <StatusWorkHoursBar
            standardWH={sumStandardH}
            leavesWH={sumLeaveH}
            totalworkWH={sumTotalH}
          />
        </TableCell>
        {calOverTime(sumStandardH, Number(calHours(sumTotalH))) < 0 ? (
          <TableCell align="center" style={{ color: "red" }}>
            {getHourMinString(Number(calOverTime(sumStandardH, Number(calHours(sumTotalH)))))}
          </TableCell>
        ) : (
          <TableCell align="center">
            {getHourMinString(Number(calOverTime(sumStandardH, Number(calHours(sumTotalH)))))}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              {wh.workHistory.length !== 0 ? (
                <Table size="small" aria-label="purchases">
                  <TableHead style={{ backgroundColor: "#E3E3E3" }}>
                    <TableRow>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">CheckIn</TableCell>
                      <TableCell align="center">CheckOut</TableCell>
                      <TableCell align="center">Note</TableCell>
                      <TableCell align="center">Standard Hours</TableCell>
                      <TableCell align="center">Work Hours</TableCell>
                      <TableCell align="center">Leave Hours</TableCell>
                      <TableCell align="center">Leave Detail</TableCell>
                      <TableCell align="center">Total Work Hours</TableCell>
                      <TableCell align="center">OverTime Hours</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ backgroundColor: "#F5F5F5" }}>
                    {wh.workHistory.map((h, i) => {
                      var rowProjectColor = chooseOtherProjectColor(wh, h.projectId);
                      return (
                        <TableRow key={i} style={{backgroundColor: `#${rowProjectColor}`}}>
                          <TableCell component="th" scope="row" align="center">
                            {moment(h.date).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {h.checkIn !== null
                              ? moment(h.checkIn).format("HH:mm")
                              : "--:--"}
                          </TableCell>
                          <TableCell align="center">
                            {h.checkOut !== null
                              ? moment(h.checkOut).format("HH:mm")
                              : "--:--"}
                          </TableCell>
                          <TableCell align="center">{h.note}</TableCell>
                          <TableCell align="center">
                            {getHourMinString(h.standardHours)}
                          </TableCell>
                          <TableCell align="center">
                            {getHourMinString(h.workHours)}
                          </TableCell>
                          <TableCell align="center">
                            {getHourMinString(h.leaveHours)}
                          </TableCell>
                          <TableCell align="center">{h.leaveDetail}</TableCell>
                          <TableCell align="center">
                            {getHourMinString(h.totalHours)}
                          </TableCell>
                          {Number(h.statusHours.toFixed(2)) < 0 ? (
                            <TableCell align="center" style={{ color: "red" }}>
                              {getHourMinString(h.statusHours)}
                            </TableCell>
                          ) : (
                            <TableCell align="center">
                              {getHourMinString(h.statusHours)}
                            </TableCell>
                          )}
                          <TableCell align="center">
                            <IconButton aria-label="task details" onClick={() => handleActivityDetailClick(h)}>
                              <ArticleOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Typography style={{ backgroundColor: "#F5F5F5" }}>
                  NO DATA
                </Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
