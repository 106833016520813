import React from "react";

import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  useTheme,
} from "@mui/material";

export default function ProjectSelector({
  setProject,
  projects,
  project,
  width,
}) {
  const theme = useTheme();
  return (
    <>
      {project == undefined ? (
        <></>
      ) : (
        <FormControl
          color="inputColor"
          sx={{ width: width, minWidth: 200 }}
          size="small"
        >
          <InputLabel>Project</InputLabel>
          <Select
            required
            //disablePortal
            labelId="demo-select-small"
            id="demo-select-small"
            value={project?.label}
            label="Project"
            onChange={(e) => {
              let selectProject = projects.find(
                (i) => i.label === e.target.value
              );
              setProject(selectProject);
            }}
            sx={{
              input: { color: theme.palette.custom.white },
              color: theme.palette.custom.white,
            }}
          >
            {projects.map((name) => (
              <MenuItem key={name.id} value={name.label}>
                {name.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}
