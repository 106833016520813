import { axiosHelper } from "../utils/axiosHelper";

export async function fetchServerToken(email) {
  return await axiosHelper.get("/api/Users/WFHLogin", { email: email });
}

export async function fetchUserSeenAnnouncement(userId) {
  return await axiosHelper.get("/api/Users/SeenAnnouncement", { employeeId: userId });
}

export async function fetchUpdateUserSeenAnnouncement(userId) {
  return await axiosHelper.post("/api/Users/SeenAnnouncement/" + userId);
}