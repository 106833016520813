import { axiosHelper } from "../utils/axiosHelper";

export async function fetchEmployeeTeam() {
  return await axiosHelper.get("/api/Employee/EmployeeTeam");
}

export async function fetchTeamMember(projectId) {
  return await axiosHelper.get("/api/Team/TeamMember", {
    projectId: projectId,
  });
}

export async function fetchTeamWorkHoursDetail(startdate, endDate, empId, projId) {
  return await axiosHelper.get("/api/Report/WorkHourExport", {
    startDate: startdate,
      endDate: endDate,
      empId: empId,
      projectId: projId
  });
}

export async function fetchProjectList() {
  return await axiosHelper.get("/api/Team");
}