import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { fetchProjectList } from "../../fetch/fetchTeam";
import LabelIcon from "@mui/icons-material/Label";

export default function WorkHistoryTeamBadge({ projects }) {
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    fetchProjectListData();
  }, []);

  const fetchProjectListData = async () => {
    const projectList = await fetchProjectList();
    if (projectList && projectList.status === 200 && projectList.data) {
      setProjectList(projectList.data);
    }
  };

  return (
    <Stack
      justifyContent={{ xs: "center", sm: "right" }}
      alignItems={{ xs: "center", sm: "right" }}
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 2, sm: 2, md: 4 }}
    >
      <Box p={1}>
        <Grid container spacing={1} direction="row">
          {projects.map((project) => {
            var projectColor = projectList.filter((x) => x.projectId == project.projectId && !project.isMainProject);
            var rowProjectColor = projectColor && projectColor.length > 0 ? projectColor[0].projectColor : "#eeeeee";

            return (
              <Grid key={project.projectId} item style={{ paddingTop: 0 }}>
                <Typography>
                  <LabelIcon
                    fontSize="small"
                    style={{
                      color: rowProjectColor,
                      marginRight: 5,
                      top: 4,
                      position: "relative",
                    }}
                  />
                  {project.projectName}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Stack>
  );
}
