import React, { useEffect, useState } from "react";

import { Stack, TextField, Skeleton } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  CustomPieChart,
  CustomVerticalBarChart,
} from "./DisplayChartComponents";

import { useQuery } from "react-query";
import { fetchTotalHours } from "../../fetch/fecthActivity";

import { colorsForCard } from "../../utils/color";
import { fetchWFOAttendance } from "../../fetch/fetchWFOAttendance";

const COLOR_CHART = colorsForCard;

export default function DisplayChart({ Data, setProjectDetail }) {
  let yesterday = new Date(new Date());
  yesterday.setHours(23, 59, 59);

  let lastweek = new Date(new Date().valueOf() - 6 * 1000 * 60 * 60 * 24);
  lastweek.setHours(0, 0, 0);

  let dataSets = [];
  let bgColor = [];
  let totalHours = [];
  let totalStatus = false;

  const [projectSelect, setProjectSelect] = useState([]);
  const [projects, setProject] = useState([]);
  const [startDate, setStartDate] = useState(lastweek);
  const [endDate, setEndDate] = useState(yesterday);

  useEffect(() => {
    if (projectSelect.length == 0) {
      setProject([
        ...Data.projectList,
        {
          description: null,
          isMainProject: false,
          isTeamLead: false,
          projectId: null,
          projectName: "WFO",
        },
      ]);
      setProjectDetail(Data.projectList[0]);
      Data.projectList.map((e) => {
        setProjectSelect((p) => [...p, e.projectName]);
      });
    }
  }, []);

  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const getDateArray = (startDate, endDate) => {
    const dateArray = [];
    let date = new Date(startDate);
    while (date <= endDate) {
      dateArray.push(new Date(date).toLocaleDateString());

      date = new Date(date.setDate(date.getDate() + 1));
    }
    return dateArray;
  };

  let labels = getDateArray(startDate, endDate);
  Data.projectList.forEach((p, index) => {
    bgColor = [...bgColor, COLOR_CHART[index]];
  });

  bgColor = [...bgColor, "#ffb8b8"];

  Data.projectList.forEach((e, index) => {
    const { data: total, status } = useQuery(
      "totalHourToken" +
        e.projectId +
        startDate.toLocaleDateString() +
        endDate.toLocaleDateString(),
      async () =>
        await fetchTotalHours(
          e.projectId,
          formatDate(startDate),
          formatDate(endDate)
        )
    );
    {
      status === "success" ? (totalStatus = true) : (totalStatus = false);
    }
    totalHours = [
      ...totalHours,
      total?.data?.reduce((a, b) => b.minuteSum / 100 + b.hourSum + a, 0),
    ];
    dataSets = [
      ...dataSets,
      {
        label: e.projectName,
        data: labels.map(
          (e) =>
            total?.data?.find(
              (t) => new Date(Date.parse(t.date)).toLocaleDateString() === e
            )?.hourSum +
              total?.data?.find(
                (t) => new Date(Date.parse(t.date)).toLocaleDateString() === e
              )?.minuteSum /
                100 || 0
        ),
        backgroundColor: bgColor[index],
      },
    ];
  });

  const { data: wfo } = useQuery(
    "wfoHourToken" +
      startDate.toLocaleDateString() +
      endDate.toLocaleDateString(),
    async () =>
      await fetchWFOAttendance(formatDate(startDate), formatDate(endDate))
  );

  function checkEmpty(data) {
    try {
      if (data == undefined) return true;
      if (data == null) return true;
      if (data.length == 0) return true;
      if (data.every((e) => e.length == 0)) return true;
      return false;
    } catch (err) {
      return true;
    }
  }
  !checkEmpty(wfo?.data) &&
    dataSets.push({
      label: "WFO",
      data: wfo?.data?.map((w) => {
        if (w.length == 0) return 0;
        return w.reduce((acc, cur) => acc + cur.hours + cur.minutes / 100, 0);
      }),
      backgroundColor: "#ffb8b8",
    });

  totalHours = [
    ...totalHours,
    wfo?.data?.reduce((a, b) => {
      if (b.length !== 0) {
        return b[0].minutes / 100 + b[0].hours + a;
      }
      return a;
    }, 0),
  ];

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          m: 4,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={1} direction={"row"}>
            <DatePicker
              label="Start Date"
              value={startDate}
              inputFormat={"dd/MM/yyyy"}
              maxDate={endDate}
              onChange={(newValue) => {
                newValue.setHours(0, 0, 0);
                if (newValue <= endDate) setStartDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              inputFormat={"dd/MM/yyyy"}
              maxDate={new Date()}
              minDate={startDate}
              onChange={(newValue) => {
                newValue.setHours(23, 59, 59);
                if (newValue >= startDate) setEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Stack>
        </LocalizationProvider>
      </Stack>
      <Stack direction="column" spacing={2} px={4}>
        {totalStatus === true ? (
          <>
            <CustomVerticalBarChart
              bgColor={bgColor}
              startDate={startDate}
              endDate={endDate}
              projects={projects}
              labels={labels}
              dataSets={dataSets}
            />
            <CustomPieChart
              bgColor={bgColor}
              setProjectDetail={setProjectDetail}
              projects={projects}
              startDate={startDate}
              endDate={endDate}
              totalHours={totalHours}
            />
          </>
        ) : (
          <>
            <Skeleton
              variant="rectangular"
              width={600}
              height={300}
              style={{ marginTop: "10px" }}
            />
            <Stack spacing={1} direction="row" justifyContent="center">
              <Skeleton variant="circular" width={300} height={300} />
              <Skeleton
                variant="rectangular"
                width={300}
                height={50}
                style={{ marginTop: "125px" }}
              />
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
}
