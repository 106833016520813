import * as React from "react";

import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Carousel from "react-material-ui-carousel";

import { useQuery } from "react-query";

import { fetchAnnounce } from "../../fetch/fetchAnnounce";

export default function Announcement() {
  const { data, status } = useQuery("announceToken", () => fetchAnnounce());

  return (
    <Box sx={{ maxWidth: 800, width: 1 }}>
      {status === "success" ? (
        <Carousel navButtonsAlwaysVisible="true">
          {data.data.map((step) => (
            <div key={step.announceId}>
              <Box
                component="img"
                sx={{
                  height: 510,
                  display: "block",
                  maxWidth: 800,
                  width: 1,
                  overflow: "hidden",
                  marginX: "auto",
                  objectFit: "contain",
                }}
                src={`https://mycos-timeattendance-api.azurewebsites.net/${step.img}`}
                alt={step.msg}
              />
            </div>
          ))}
        </Carousel>
      ) : (
        <Skeleton variant="rectangular" width={800} height={510} />
      )}
    </Box>
  );
}
