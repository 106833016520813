import { Box, useTheme, Stack } from "@mui/material";

import useWindowSize from "../../hooks/useWindowSize";

export default function NoActivity() {
  const theme = useTheme();
  const windowSize = useWindowSize();
  return (
    <Stack
      direction="row"
      sx={{
        mx: "auto",
        width: 0.95,
        minWidth: "fit-content",
        p: "15px 0px",
        alignItems: "center",
        borderRadius: "5px",
        backgroundColor: theme.palette.custom.white,
        whiteSpace: "nowrap",
      }}
    >
      <Box
        sx={{
          width: 0.15,
          minWidth: 0.15,
          textAlign: "center",
          px: "10px",
          fontSize:
            windowSize.width > 1100 ? 16 : windowSize.width > 720 ? 14 : 12,
        }}
      >
        No activities
      </Box>
      <Box
        sx={{
          width: 0.3,
          minWidth: 200,
          maxWidth: 250,
        }}
      ></Box>
    </Stack>
  );
}
