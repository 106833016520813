import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { clearAllTokens } from "../../utils/tokenHelper";
import "./pageResponse.css";

const Page500Component = () => {
  const navigate = useNavigate();
  
  const onBtnClick = () => {
    clearAllTokens();
    navigate("/login");
  };
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          background: "linear-gradient(180deg, #010101 30%, #4a4a4a 180%)",
        }}
      >
        <div className="stars">
          <Container>
            <Grid container>
              <Grid>
                <img
                  src="http://salehriaz.com/404Page/img/earth.svg"
                  width="100px"
                  alt="earth"
                />
                <img
                  src="http://salehriaz.com/404Page/img/moon.svg"
                  width="80px"
                  alt="moon"
                />
              </Grid>
              <Grid>
                <img
                  src="http://salehriaz.com/404Page/img/rocket.svg"
                  width="40px"
                  alt="rocket"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography
                    align="center"
                    style={{
                      color: "white",
                      fontSize: "10rem",
                      fontWeight: 800,
                    }}
                    variant="h1"
                  >
                    500
                  </Typography>
                  <Typography
                    align="center"
                    style={{ color: "white", letterSpacing: "1em" }}
                  >
                    INTERNAL SERVER
                  </Typography>
                  <Typography
                    align="center"
                    style={{ color: "white", letterSpacing: "1em" }}
                  >
                    ERROR
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignItems="center">
                <Button
                  onClick={onBtnClick}
                  variant="outlined"
                  color="inherit"
                  style={{
                    marginTop: "25px",
                    color: "#FFCB39",
                    borderRadius: "20px",
                  }}
                >
                  GO TO LOGIN
                </Button>

              </Grid>
            </Grid>
            <Grid style={{ marginLeft: "70%" }}>
              <img
                className="object_astronaut"
                src="http://salehriaz.com/404Page/img/astronaut.svg"
                width="140px"
                alt="astronaut"
              />
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Page500Component;
