import React, { useRef, useState } from "react";

import {
  Box,
  Stack,
  Divider,
  useTheme,
  Typography,
  Tooltip,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import { fetchInsertActivity } from "../../fetch/fecthActivity";
import {
  fetchInsertActivityLunchBreak,
  fetchActivityLunchBreak,
} from "../../fetch/fecthActivityLunchBreak";
import { useMutation, useQueryClient } from "react-query";

import useWindowSize from "../../hooks/useWindowSize";
import { useStyles } from "../../hooks/useStyles";
import toast from "react-hot-toast";
import { sanitizeHtmlContent } from "../../utils/stringHelper";

export default function ActivityCard({
  STATUS_COLORS,
  task,
  handleOpenEditModal,
  handleOpenDeleteModal,
  handleRefreshData
}) {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const windowSize = useWindowSize();

  const classes = useStyles();

  const [openMenu, setOpenMenu] = useState(false);

  const menuRef = useRef(null);

  const { mutate: mutateDup } = useMutation(fetchInsertActivity, {
    onSuccess: () => {
      toast.success("Activity is duplicated successfully.");
      handleRefreshData();
    },
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries(["activities", new Date().toDateString()]);
      queryClient.invalidateQueries([
        "totalWorkHours",
        new Date().toDateString(),
      ]);
    },
  });

  const { mutate: mutateLunchBreak } = useMutation(
    fetchInsertActivityLunchBreak,
    {
      onError: () => {},
      onSettled: (data) => {
        queryClient.invalidateQueries([
          "activitiesLunchBreak",
          new Date(data.date).toDateString(),
        ]);
      },
    }
  );

  const duplicate = () => {
    fetchActivityLunchBreak(new Date().toISOString().slice(0, 10)).then(
      function (result) {
        var ch = result;
        if (!ch[0]) {
          mutateLunchBreak({
            ActivityLunchBreakId: 0,
            EmployeeId: task.employeeId,
            Date: new Date().toISOString().slice(0, 10),
            IsIncludeBreak: false,
          });
        }
      }
    );
    mutateDup({
      ActivityId: 0,
      Hours: task.hours,
      Detail: task.detail,
      WorkFromHome: task.workFromHome,
      EmployeeId: task.employeeId,
      ProjectId: task.projectId,
      Date: new Date().toISOString().slice(0, 10),
      Minutes: task.minutes,
      StartTime: null,
      EndTime: null,
      Status: "In process",
    });
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          mx: "auto",
          width: 0.95,
          p: "20px 0px",
          alignItems: "center",
          borderRadius: "5px",
          borderLeft: windowSize.width <= 720 ? 10 : 0,
          borderColor:
            windowSize.width <= 720
              ? task.status === "In process"
                ? STATUS_COLORS.inProcess
                : task.status === "Pending"
                ? STATUS_COLORS.pending
                : STATUS_COLORS.done
              : theme.palette.custom.white,
          backgroundColor: theme.palette.custom.white,
          whiteSpace: "nowrap",
          justifyContent: "space-between",
          fontSize:
            windowSize.width <= 720 ? 12 : windowSize.width <= 1100 ? 14 : 15,
        }}
      >
        <Tooltip
          title={<span>{task?.projectName}</span>}
          placement="right"
          followCursor
        >
          <Typography
            noWrap={true}
            sx={{
              cursor: "default",
              width: 0.10,
              minWidth: 0.10,
              textAlign: "center",
              px: 1,
              fontWeight: "bold",
              color: theme.palette.custom.primary,
              fontSize:
                windowSize.width <= 720
                  ? 12
                  : windowSize.width <= 1100
                  ? 14
                  : 15,
            }}
          >
            {task?.projectName}
          </Typography>
        </Tooltip>
        <Box
          sx={{
            width: 0.45,
            minWidth: "45%",
            maxWidth: "45%",
          }}
        >
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-line" }}>{task?.detail}</span>
            }
            placement="right"
            followCursor
          >
            <Typography
              className={classes.multiLineEllipsis}
              whiteSpace="pre-line"
              sx={{
                cursor: "default",
                fontSize:
                  windowSize.width <= 720
                    ? 12
                    : windowSize.width <= 1100
                    ? 14
                    : 15,
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: sanitizeHtmlContent(task?.detail)}}></span>
            </Typography>
          </Tooltip>
        </Box>
        {windowSize.width > 720 ? (
          <Typography
            sx={{
              minWidth:
                windowSize.width > 1100 ? 90 : windowSize.width > 720 ? 80 : 65,
              maxWidth:
                windowSize.width > 1100 ? 90 : windowSize.width > 720 ? 80 : 65,
              color:
                task.status === "In process"
                  ? STATUS_COLORS.inProcess
                  : task.status === "Pending"
                  ? STATUS_COLORS.pending
                  : STATUS_COLORS.done,
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowSize.width <= 720
                  ? 12
                  : windowSize.width <= 1100
                  ? 14
                  : 15,
            }}
          >
            {task.status}
          </Typography>
        ) : (
          <></>
        )}

        <Box sx={{ width: 0.10, textAlign: "center" }}>{`${
          task?.startTime === null ? "--:-- " : task?.startTime
        }-${task?.endTime === null ? " --:-- " : task?.endTime}`}</Box>
        <Box
          sx={{ width: 0.10, pl: "5px", textAlign: "center" }}
        >{`${task?.hours}hrs ${task?.minutes}mins`}</Box>
        {windowSize.width <= 1280 ? (
          <>
            <MoreVertOutlinedIcon
              ref={menuRef}
              onClick={() => {
                setOpenMenu((v) => !v);
              }}
              sx={{
                cursor: "pointer",
                color: "black",
                mr: 2,
              }}
            />
            <Popper
              open={openMenu}
              anchorEl={menuRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal={true}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                      <MenuList
                        autoFocusItem={openMenu}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                      >
                        <MenuItem
                          disabled={task?.isSynced}
                          onClick={() => {
                            setOpenMenu(false);
                            handleOpenEditModal(task);
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenMenu(false);
                            duplicate();
                          }}
                        >
                          Duplicate
                        </MenuItem>
                        <MenuItem
                          disabled={task?.isSynced}
                          onClick={() => {
                            setOpenMenu(false);
                            handleOpenDeleteModal(task);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        ) : (
          <>
            <Stack
              spacing={2}
              direction={"row"}
              sx={{ px: "10px", width: "fit-content" }}
            >
              <Tooltip
                title={
                  task?.isSynced ? (
                    <span>Email was sent</span>
                  ) : (
                    <span>Edit</span>
                  )
                }
                placement="right"
                followCursor
              >
                <EditIcon
                  sx={{
                    color: task?.isSynced
                      ? theme.palette.custom.grey
                      : theme.palette.custom.edit,
                  }}
                  style={{
                    cursor: task?.isSynced ? "default" : "pointer",
                  }}
                  onClick={() => {
                    if (!task?.isSynced) handleOpenEditModal(task);
                  }}
                />
              </Tooltip>
              <Tooltip
                title={<span>Duplicate</span>}
                placement="right"
                followCursor
              >
                <FileCopyIcon
                  sx={{
                    color: theme.palette.custom.duplicate,
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    duplicate();
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  task?.isSynced ? (
                    <span>Email was sent</span>
                  ) : (
                    <span>Delete</span>
                  )
                }
                placement="right"
                followCursor
              >
                <DeleteIcon
                  sx={{
                    color: task?.isSynced
                      ? theme.palette.custom.grey
                      : theme.palette.custom.delete,
                  }}
                  style={{
                    cursor: task?.isSynced ? "default" : "pointer",
                  }}
                  onClick={() => {
                    if (!task?.isSynced) handleOpenDeleteModal(task);
                  }}
                />
              </Tooltip>
            </Stack>
          </>
        )}
      </Stack>
      <Divider sx={{ width: 0.95, mx: "auto" }} />
    </>
  );
}
