export default function StatusWorkHoursBar({
  standardWH,
  leavesWH,
  totalworkWH,
}) {
  var calLeaves = (leavesWH * 100) / standardWH;
  var calTotalwork = (totalworkWH * 100) / standardWH;
  calTotalwork = calTotalwork >= 100 ? (calTotalwork = 100) : calTotalwork;

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "30px",
          backgroundColor: "#e2e2e2",
          color: "white",
          textAlign: "center",
          position: "relative",
        }}
        title={"Missing " + (totalworkWH - standardWH).toFixed(2) + "Hr."}
      >
        <div
          title={"WorkTime " + (totalworkWH - leavesWH).toFixed(2) + "Hr."}
          style={{
            height: "100%",
            backgroundColor: "#6EC1B2",
            float: "left",
            width: calTotalwork + "%",
          }}
        ></div>
        <div
          title={"Leave " + leavesWH + "Hr."}
          style={{
            height: "100%",
            backgroundColor: "#FFAB00",
            float: "left",
            position: "absolute",
            width: calLeaves + "%",
          }}
        ></div>
      </div>
    </>
  );
}
