import React, { useState, useRef, useEffect } from "react";

import {
  Avatar,
  Stack,
  Typography,
  ClickAwayListener,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@mui/material";
import Grow from "@mui/material/Grow";

import { SignOutButton } from "../components/SignOutButton";

import { useAuth } from "../contexts/AuthContext";
import useWindowSize from "../hooks/useWindowSize";

export default function Footer({ isHideSidebar }) {
  const { logout, getUser } = useAuth();
  const [openSignout, setOpenSignout] = useState(false);
  const [user, setUser] = useState(null);
  const windowSize = useWindowSize();
  const anchorRef = useRef(null);

  useEffect(() => {
    async function fetchUser() {
      const user = await getUser();
      setUser(user);
    }
    fetchUser();
  }, []);

  const handleOpenSignout = () => {
    if (isHideSidebar || windowSize.width <= 1100) {
      setOpenSignout(true);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenSignout(false);
  };

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        marginTop: "auto",
        px: "5px",
        pb: 1,
        whiteSpace: "nowrap",
      }}
    >
      <Avatar
        ref={anchorRef}
        sx={{ width: "30px", height: "30px" }}
        alt="userProfile"
        src={
          user?.gender === "F"
            ? "https://i.pinimg.com/564x/2b/c0/fe/2bc0feb541b86dfe46cbd70c2bb63b7f.jpg"
            : "https://i.pinimg.com/564x/51/f6/fb/51f6fb256629fc755b8870c801092942.jpg"
        }
        onClick={handleOpenSignout}
      />
      <Popper
        open={openSignout}
        anchorEl={anchorRef.current}
        placement="right"
        transition
        disablePortal={true}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={openSignout}>
                  <MenuItem onClick={logout}>Signout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {!(isHideSidebar || windowSize.width < 1100) && (
        <>
          <Typography
            noWrap={true}
            sx={{
              m: 0,
              marginLeft: "10px",
              fontSize: 15,
              fontWeight: 400,
            }}
            title={user?.firstName + " " + user?.lastName}
          >
            {user?.firstName} {user?.lastName}
          </Typography>
          <SignOutButton />
        </>
      )}
    </Stack>
  );
}
