import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./contexts/AuthContext";
import Announcements from "./pages/Announcement";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import MainLayout from "./layouts/MainLayout";
import Activity from "./pages/Activity";
import Dashboard from "./pages/Dashboard";
import Calendar from "./pages/Calendar";
import Schedule from "./pages/Schedule";
import InternalError from "./pages/InternalError";
import TeamActivity from "./pages/TeamActivity";

function App() {
  //console.log("ENV:", process.env);

  return (
    <>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route exact path="/" element={<MainLayout />}>
              <Route index element={<Navigate to="/activity" replace />} />
              <Route path="/activity">
                <Route
                  index
                  element={
                    <ProtectedRoute role="allowAllRoles">
                      <Activity />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":projectDate"
                  element={
                    <ProtectedRoute role="allowAllRoles">
                      <Activity />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="announcement"
                element={
                  <ProtectedRoute role="allowAllRoles">
                    <Announcements />
                  </ProtectedRoute>
                }
              />
              <Route
                path="dashboard"
                element={
                  <ProtectedRoute role="allowAllRoles">
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="calendar"
                element={
                  <ProtectedRoute role="allowAllRoles">
                    <Calendar />
                  </ProtectedRoute>
                }
              />
              <Route
                path="schedule"
                element={
                  <ProtectedRoute role="allowAllRoles">
                    <Schedule />
                  </ProtectedRoute>
                }
              />
              <Route
                path="teams"
                element={
                  <ProtectedRoute role="allowAllRoles">
                    <TeamActivity />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/error500" element={<InternalError />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
