import { Container, Stack, useTheme } from "@mui/material";
import React from "react";
import {
  CalendarHeader,
  CalendarContent,
} from "../components/CalendarComponents";

export default function Calendar() {
  const [date, setDate] = React.useState(new Date());
  const theme = useTheme();
  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          background: theme.palette.custom.white,
          borderRadius: "5px",
        }}
      >
        <Stack
          direction="column"
          sx={{
            width: 1,
            height: 1,
            backgroundColor: theme.palette.custom.white,
          }}
        >
          <CalendarHeader date={date} setDate={setDate} />
          <CalendarContent month={date.getMonth()} year={date.getFullYear()} />
        </Stack>
      </Container>
    </>
  );
}
