export const dateMonthNameFullYear = (date) => {
  if (typeof date === "string") {
    date = new Date(date);
  }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day} ${monthNames[monthIndex]}, ${year}`;
};

export const yearMonthDate = (date) => {
  // receive type date
  // return string "yyyy-mm-dd"
  if (typeof date === "string") {
    date = new Date(date);
  }
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};
