import React from "react";
import { Stack, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import useWindowSize from "../hooks/useWindowSize";

export default function Header({ isHideSidebar, handleHideSidebar }) {
  const windowSize = useWindowSize();
  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          mb: "30px",
        }}
      >
        <IconButton onClick={() => handleHideSidebar(windowSize.width)}>
          <MenuIcon sx={{ fontSize: 20 }} />
        </IconButton>
        {!isHideSidebar && windowSize.width >= 1100 && (
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              whiteSpace: "nowrap",
              alignSelf: "center",
            }}
          >
            Mycos Task Tracker
          </Typography>
        )}
      </Stack>
    </>
  );
}
