import React from "react";

import {
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function ProjectsSelector({
  projectsSelected,
  projectsSelectedChange,
  projects,
  width,
}) {
  const menu_props = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: width,
      },
    },
  };
  return (
    <FormControl size="small" sx={{ width: width, ml: 2 }}>
      <InputLabel sx={{ my: "auto" }}>Project</InputLabel>
      <Select
        multiple
        value={projectsSelected}
        onChange={projectsSelectedChange}
        input={<OutlinedInput label="Project" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={menu_props}
      >
        {projects.map((i) => (
          <MenuItem key={i} value={i}>
            <Checkbox checked={projectsSelected.indexOf(i) > -1} />
            <ListItemText primary={i} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
