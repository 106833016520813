import React, { useState, useEffect } from "react";

import {
  Box,
  Typography,
  TextField,
  Stack,
  CircularProgress,
} from "@mui/material";

import {
  NoActivity,
  AddActivity,
  EditModal,
  DeleteModal,
  ActivityCard,
  IncludeLunchBreak,
} from "../components/ActivityComponents";

import WFOEditModal from "../components/ActivityComponents/WFOEditModal";

import { useQueries, useQuery } from "react-query";
import {
  fetchActivities,
  fetchProjectEmployee,
  fetchTotalHours,
} from "../fetch/fecthActivity";
import {
  fetchActivityLunchBreak,
  fetchUpdateActivityLunchBreak,
} from "../fetch/fecthActivityLunchBreak";

import { fetchTimeAttendancesByCurrentEmployee } from "../fetch/fetchTimeAttendance";

import { useParams } from "react-router-dom";

import { dateMonthNameFullYear, yearMonthDate } from "../utils/dateFormat";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useNavigate } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import { useMutation, useQueryClient } from "react-query";

import { statusColors } from "../utils/color";
import WFOActivityCard from "../components/ActivityComponents/WFOActivityCard";
import moment from "moment";

const STATUS_LIST = ["In process", "Pending", "Done"];
const STATUS_COLORS = statusColors;

export default function Activity() {
  const { projectDate } = useParams();
  const windowSize = useWindowSize();

  const navigate = useNavigate();

  const [searchDate, setSearchDate] = useState(
    projectDate ? new Date(projectDate) : null
  );
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [taskSelected, setTaskSelected] = useState(null);
  const [projects, setProjects] = useState([]);
  const [days, setDays] = useState([]);
  const [tasksToday, setTasksToday] = useState();
  const [isLoadingActivities, setIsLoadingActivities] = useState(true);
  const [idRefreshData, setIdRefreshData] = useState(0);
  const [totalWorkHoursObj, setTotalWorkHoursObj] = useState(null);
  const [openWFOEditModal, setWFOOpenEditModal] = useState(false);
  const [wfoSelected, setWFOSelected] = useState(null);
  const [wfoList, setWFOList] = useState(null);
  const [attQueriesDone, setAttQueriesDone] = useState(false);

  const queryClient = useQueryClient();

  const { data: employeeProjects, isLoading: projectsLoading, refetch: getEmployeeProjects } = 
    useQuery("employeeProjects", () => fetchProjectEmployee(), {
    enabled: false,
    onError: () => {
      navigate("/error500");
    },
  });

  const { isLoading: totalWorkHoursLoading, refetch: getTotalWorkHours } =
    useQuery(
      ["totalWorkHours", new Date().toDateString(), searchDate],
      () =>
        fetchTotalHours(
          0,
          searchDate
            ? yearMonthDate(searchDate)
            : yearMonthDate(
                new Date(new Date().setDate(new Date().getDate() - 6))
              ),
          searchDate ? yearMonthDate(searchDate) : yearMonthDate(new Date())
        ),
      {
        enabled: false,
        onSuccess: (res) => {
          if (res.status !== 200) return navigate("/error500");
          setDays(res.data);
          setTotalWorkHoursObj(res);
        },
        onError: () => {
          navigate("/error500");
        },
      }
    );

  const activitiesQueries = useQueries(
    days?.map((d) => {
      return {
        queryKey: ["activities", new Date(d.date).toDateString()],
        queryFn: () => fetchActivities(d.date),
        enabled: false,
      };
    })    
  );

  const activitiesLunchBreakQueries = useQueries(
    days?.map((d) => {
      return {
        queryKey: ["activitiesLunchBreak", new Date(d.date).toDateString()],
        queryFn: () => fetchActivityLunchBreak(d.date),
        enabled: false,
      };
    })
  );

  const { refetch: getTimeAttendanceByCurrentEmployee } = useQuery(
    ["timeAttendancesByCurrentEmployee", new Date().toDateString(), searchDate],
    () =>
      fetchTimeAttendancesByCurrentEmployee(
        searchDate
          ? yearMonthDate(searchDate)
          : yearMonthDate(
              new Date(new Date().setDate(new Date().getDate() - 6))
            ),
        searchDate ? yearMonthDate(searchDate) : yearMonthDate(new Date())
      ),
    {
      enabled: false,
      onSuccess: (res) => {
        if (res.status !== 200) 
          return navigate("/error500");

        setWFOList([...res.data]);
      },
      onError: () => {
        navigate("/error500");
      },
    }
  );

  const { mutate } = useMutation(fetchUpdateActivityLunchBreak, {
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries(["activitiesLunchBreak"]);
    },
  });

  const handleActivitiesQueries = async () => {
    const refetchPromises = activitiesQueries.map(query => query.refetch());
    await Promise.all(refetchPromises);
  };

  const handleActivitiesLunchBreakQueries = async () => {
    const refetchPromises = activitiesLunchBreakQueries.map(query => query.refetch());
    await Promise.all(refetchPromises);
  };

  useEffect(() => {
    // Step 1: Manually trigger the query when the component mounts
    getEmployeeProjects();
    getTotalWorkHours();
    getTimeAttendanceByCurrentEmployee(); 
  }, []);

  useEffect(() => {
    // Step 3: Need to wait all activitiesQueries are done
    if (!totalWorkHoursLoading) {
      handleActivitiesQueries();
      handleActivitiesLunchBreakQueries();
    }
  }, [totalWorkHoursLoading]);

  useEffect(() => {
    // Step 3: Need to wait all activitiesQueries are done
    const allSuccess = activitiesQueries.every((query) => query.isSuccess);
    if (allSuccess) {
      setAttQueriesDone(true);
    }
  }, [activitiesQueries]);

  useEffect(() => {
    // Step 4
    if (attQueriesDone) {
      setIsLoadingActivities(false);
      setTasksToday(
        activitiesQueries?.[0]?.data?.[activitiesQueries?.[0]?.data?.length - 1]
      );
    }

    if (!projectsLoading) {
      setupProjects();
    }
  }, [attQueriesDone, projectsLoading]);

  useEffect(() => {
    getTotalWorkHours();
    getTimeAttendanceByCurrentEmployee();
    handleActivitiesQueries();
    handleActivitiesLunchBreakQueries();
  }, [idRefreshData, searchDate]);

  const setupProjects = () => {
    let array = [];
    employeeProjects?.map((p) => {
      let { projectName, projectId } = p;
      array.push({ label: projectName, id: projectId });
    });
    setProjects(array);
  };

  const handleOpenEditModal = (task) => {
    setOpenEditModal(true);
    setTaskSelected(task);
  };

  const handleOpenWFOEditModal = (wfo) => {
    setWFOOpenEditModal(true);
    setWFOSelected(wfo);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setTaskSelected(null);
  };

  const handleCloseWFOEditModal = () => {
    setWFOOpenEditModal(false);
    setWFOSelected(null);
  };

  const handleOpenDeleteModal = (task) => {
    setOpenDeleteModal(true);
    setTaskSelected(task);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setTaskSelected(null);
  };

  const checkBoxBreak = (lunchbreak, hoursOfBreak) => {
    if (lunchbreak?.isIncludeBreak == true && hoursOfBreak < 1) {
      mutate({
        ActivityLunchBreakId: lunchbreak.activityLunchBreakId,
        EmployeeId: lunchbreak.employeeId,
        Date: lunchbreak.date,
        IsIncludeBreak: !lunchbreak.isIncludeBreak,
      });
    }
  };

  const handleRefreshData = () => {
    setIdRefreshData((idRefreshData) => idRefreshData + 1);
  };

  const calculateTotalWorkHours = (currentItem) => {
    let wfoHours = 0,
      wfoMinutes = 0;
    let resultHours = 0,
      resultMinutes = 0;

    const filterDate = moment(currentItem.date).format("YYYY-MM-DD");
    const matchItem = wfoList.filter(
      (x) => moment(x.checkIn).format("YYYY-MM-DD") == filterDate
    );

    if (matchItem && matchItem.length > 0 && matchItem[0].hours > 0) {
      let decimalStrs = parseFloat(matchItem[0].hours)
        .toFixed(2)
        .toString()
        .split(".");

      if (decimalStrs && decimalStrs.length == 2) {
        wfoHours = Number.parseInt(decimalStrs[0]);
        wfoMinutes = Number.parseInt(decimalStrs[1]);
      } else if (decimalStrs && decimalStrs.length == 1) {
        wfoHours = Number.parseInt(decimalStrs[0]);
      }

      resultHours =
        currentItem?.hourSum +
        wfoHours +
        Number.parseInt((currentItem?.minuteSum + wfoMinutes) / 60);
      resultMinutes = (currentItem?.minuteSum + wfoMinutes) % 60;
    } else {
      resultHours =
        currentItem?.hourSum + Number.parseInt(currentItem?.minuteSum / 60);
      resultMinutes = currentItem?.minuteSum % 60;
    }

    return resultHours > 0 || resultMinutes > 0
      ? `Total work hours: ${resultHours} hr ${resultMinutes} min`
      : "";
  };

  return (
    <Stack direction="column">
      {taskSelected && (
        <EditModal
          openEditModal={openEditModal}
          handleCloseEditModal={handleCloseEditModal}
          taskInputValue={taskSelected}
          statusList={STATUS_LIST}
          projects={projects}
          handleRefreshData={handleRefreshData}
        />
      )}
      {wfoSelected && (
        <WFOEditModal
          wfoSelected={wfoSelected}
          openWFOEditModal={openWFOEditModal}
          handleCloseWFOEditModal={handleCloseWFOEditModal}
          handleRefreshData={handleRefreshData}
        />
      )}
      <DeleteModal
        openDeleteModal={openDeleteModal}
        task={taskSelected}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleRefreshData={handleRefreshData}
      />
      {projectsLoading ? (
        <CircularProgress sx={{ mx: "auto" }} />
      ) : (
        <>
          <AddActivity
            projects={projects}
            tasksToday={tasksToday}
            statusList={STATUS_LIST}
            handleRefreshData={handleRefreshData}
          />

          <Stack
            direction={"row"}
            width={windowSize.width >= 750 ? 0.98 : 1}
            alignSelf="center"
            justifyContent={"space-between"}
          >
            <Box my={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Search by date"
                  value={searchDate}
                  onChange={(date) => {
                    if (date === null || date === undefined)
                      return setSearchDate(null);
                    if (date.getTime() > new Date(1970, 0, 1).getTime()) {
                      setSearchDate(date);
                    }
                  }}
                  InputProps={{
                    inputMode: "numeric",
                    readOnly: true,
                  }}
                  inputFormat="dd/MM/yyyy"
                  inputVariant="outlined"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: 200,
                      }}
                      size="small"
                      variant="outlined"
                      label="Search by date"
                      type="date"
                      inputlabelprops={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            {windowSize.width > 720 ? (
              <></>
            ) : (
              <Stack
                width={80}
                padding={1}
                margin={1}
                spacing={1}
                bgcolor={"white"}
                borderRadius={"10px"}
              >
                <Typography
                  fontSize={10}
                  sx={{
                    color: STATUS_COLORS.inProcess,
                    fontWeight: "bold",
                    pl: 1,
                    borderLeft: 5,
                    borderColor: STATUS_COLORS.inProcess,
                  }}
                >
                  In process
                </Typography>
                <Typography
                  fontSize={10}
                  sx={{
                    color: STATUS_COLORS.pending,
                    fontWeight: "bold",
                    pl: 1,
                    borderLeft: 5,
                    borderColor: STATUS_COLORS.pending,
                  }}
                >
                  Pending
                </Typography>
                <Typography
                  fontSize={10}
                  sx={{
                    color: STATUS_COLORS.done,
                    fontWeight: "bold",
                    pl: 1,
                    borderLeft: 5,
                    borderColor: STATUS_COLORS.done,
                  }}
                >
                  Done
                </Typography>
              </Stack>
            )}
          </Stack>
        </>
      )}
      {!projectsLoading && totalWorkHoursLoading ? (
        <CircularProgress sx={{ mx: "auto" }} />
      ) : (
        totalWorkHoursObj?.data?.map((value, index) => (
          <Box key={index} width={1} height={1} alignSelf="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                my: 0.5,
              }}
            >
              <Typography
                sx={{
                  fontSize:
                    windowSize.width > 1100
                      ? 20
                      : windowSize.width > 720
                      ? 18
                      : 16,
                  fontWeight: 500,
                }}
              >
                {new Date().toDateString() ==
                new Date(value?.date).toDateString()
                  ? "Today"
                  : dateMonthNameFullYear(value?.date)}
              </Typography>
              <Stack
                direction="column"
                width="fit-content"
                justifyContent={"flex-end"}
              >
                {activitiesQueries[index]?.data?.length > 0 &&
                  activitiesLunchBreakQueries[index]?.data?.map(
                    (lunchBreak, i) => (
                      <div key={i}>
                        <Typography
                          sx={{
                            fontSize:
                              windowSize.width > 1100
                                ? 16
                                : windowSize.width > 720
                                ? 14
                                : 12,
                          }}
                        >
                          {calculateTotalWorkHours(value)}
                        </Typography>
                        {checkBoxBreak(lunchBreak, value?.hourSum)}
                        <IncludeLunchBreak
                          key={lunchBreak?.activityLunchBreakId}
                          hoursOfTask={value?.hourSum}
                          lunch={lunchBreak}
                          data={activitiesQueries[index]?.data}
                          handleRefreshData={handleRefreshData}
                        />
                      </div>
                    )
                  )}
                {
                  //Case: When user filled in only WFO time attendance only
                  activitiesQueries[index]?.data &&
                  activitiesQueries[index]?.data.length == 0 &&
                  wfoList &&
                  wfoList.length > 0 ? (
                    <Typography
                      sx={{
                        fontSize:
                          windowSize.width > 1100
                            ? 16
                            : windowSize.width > 720
                            ? 14
                            : 12,
                      }}
                    >
                      {calculateTotalWorkHours(value)}
                    </Typography>
                  ) : (
                    ""
                  )
                }
              </Stack>
            </Box>
            {isLoadingActivities ? (
              <CircularProgress sx={{ mx: "auto" }} />
            ) : (
              <>
                {activitiesQueries[index]?.data?.filter((e) => e.workFromHome)
                  .length > 0 ? (
                  activitiesQueries?.[index]?.data
                    ?.filter((e) => e.workFromHome)
                    .map((activity, i) => {
                      return (
                        <div key={i}>
                          <ActivityCard
                            key={activity?.activityId}
                            STATUS_COLORS={STATUS_COLORS}
                            task={activity}
                            statusList={STATUS_LIST}
                            setTaskInputValue={setTaskSelected}
                            handleOpenEditModal={handleOpenEditModal}
                            handleOpenDeleteModal={handleOpenDeleteModal}
                            handleRefreshData={handleRefreshData}
                          />
                        </div>
                      );
                    })
                ) : (
                  <NoActivity />
                )}
              </>
            )}
            <br />
            {wfoList && wfoList.length > 0 ? (
              <div>
                <WFOActivityCard
                  wfoItems={wfoList}
                  itemDate={value}
                  statusColors={STATUS_COLORS}
                  handleOpenWFOEditModal={handleOpenWFOEditModal}
                  handleOpenEditModal={handleOpenEditModal}
                  handleOpenDeleteModal={handleOpenDeleteModal}
                />
              </div>
            ) : (
              ""
            )}
          </Box>
        ))
      )}
    </Stack>
  );
}
