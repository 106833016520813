import { TextField, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function DateTimeInput({
  label,
  setValue,
  value,
  isStartTime,
  startTime,
  isError,
}) {
  let st = startTime;

  const theme = useTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        inputFormat="dd/MM/yyyy HH:mm"
        renderInput={(props) => (
          <TextField
            {...props}
            color="inputColor"
            sx={{
              width: 230,
              color: theme.palette.custom.white,
              border: theme.palette.custom.white,
              input: { color: theme.palette.custom.white },
              svg: { color: theme.palette.custom.white },
            }}
            inputlabelprops={{
              style: {
                color: theme.palette.custom.white,
                borderColor: theme.palette.custom.white,
              },
            }}
            size="small"
            error={isError}
          />
        )}
        ampm={false}
        label={label}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        maxDate={isStartTime ? new Date() : new Date(st).setHours(+24)}
        minDate={isStartTime ? undefined : st}
      />
    </LocalizationProvider>
  );
}
