import { axiosHelper } from "../utils/axiosHelper";

export async function fetchActivityLunchBreak(date) {
  return await axiosHelper
    .get("/api/Activity/select/break/employee/date", {
      date,
    })
    .then((r) => r.data);
}

export async function fetchInsertActivityLunchBreak(newActivityLunchBreak) {
  return await axiosHelper.post("/api/Activity/insert/lunchbreak", {
    activityLunchBreakId: newActivityLunchBreak.ActivityLunchBreakId,
    employeeId: newActivityLunchBreak.EmployeeId,
    date: newActivityLunchBreak.Date,
    isIncludeBreak: newActivityLunchBreak.IsIncludeBreak,
  });
}

export async function fetchUpdateActivityLunchBreak(newActivityLunchBreak) {
  return await axiosHelper.post(
    "/api/Activity/update/lunchbreak",
    newActivityLunchBreak
  );
}
