import React from "react";

import { Stack, Typography } from "@mui/material";

import useWindowSize from "../../../hooks/useWindowSize";

export default function TimeScale({ maxHour }) {
  let componentArray = [];

  const windowSize = useWindowSize();

  for (let i = 0; i <= maxHour; i++) {
    if (i < 10)
      componentArray.push(
        <Typography
          key={i}
          sx={{
            minWidth: windowSize.width >= 700 ? 0.1 : 0.18,
          }}
        >
          {` ${i}`}
        </Typography>
      );
    else
      componentArray.push(
        <Typography
          key={i}
          sx={{ minWidth: windowSize.width >= 700 ? 0.1 : 0.18 }}
        >
          {i}
        </Typography>
      );
  }
  return (
    <Stack direction="row" sx={{ my: "auto" }}>
      {componentArray}
    </Stack>
  );
}
