import { TextField, useTheme } from "@mui/material";

export default function MinuteInput({ minutes, setMinutes, isError }) {
  const theme = useTheme();

  return (
    <TextField
      id="standard-number"
      label="Minutes"
      type="number"
      color="inputColor"
      value={minutes}
      onChange={(e) => {
        if (e.target.value > 59) e.target.value = 59;
        else if (e.target.value < 0) e.target.value = 0;
        setMinutes(e.target.value);
      }}
      inputlabelprops={{
        shrink: true,
        style: { color: theme.palette.custom.white },
      }}
      variant="standard"
      sx={{ width: 50, input: { color: theme.palette.custom.white } }}
      InputProps={{ inputProps: { min: 0, max: 59 } }}
      size="small"
      error={isError}
    />
  );
}
