import React, { useEffect, useState } from "react";

import {
  Collapse,
  Box,
  Stack,
  Divider,
  useTheme,
  Typography,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import useWindowSize from "../../hooks/useWindowSize";
import { useStyles } from "../../hooks/useStyles";
import { sanitizeHtmlContent } from "../../utils/stringHelper";
import NoActivity from "./NoActivity";
import PopoverAction from "./PopoverAction";

const WFOActivityCollapseCard = ({
  STATUS_COLORS,
  task,
  handleOpenEditModal,
  handleOpenDeleteModal,
  openCollapse,
}) => {
  const theme = useTheme();
  const windowSize = useWindowSize();

  const classes = useStyles();

  const [isCollapse, setIsCollapes] = useState(false);

  useEffect(() => {
    setIsCollapes(openCollapse);
  }, [openCollapse]);

  return (
    <>
      <Collapse in={isCollapse} timeout="auto" unmountOnExit sx={{ px: "3%" }}>
        {!(task && task.length) > 0 ? (
          <NoActivity />
        ) : (
          task.map((item) => {
            return (
              <div key={item.activityId}>
                <Stack
                  direction="row"
                  sx={{
                    mx: "auto",
                    width: 0.95,
                    p: "20px 0px",
                    alignItems: "center",
                    borderRadius: "5px",
                    borderLeft: windowSize.width <= 720 ? 10 : 0,
                    borderColor:
                      windowSize.width <= 720
                        ? item.status === "In process"
                          ? STATUS_COLORS.inProcess
                          : item.status === "Pending"
                          ? STATUS_COLORS.pending
                          : STATUS_COLORS.done
                        : theme.palette.custom.white,
                    backgroundColor: theme.palette.custom.white,
                    whiteSpace: "nowrap",
                    justifyContent: "space-between",
                    fontSize:
                      windowSize.width <= 720
                        ? 12
                        : windowSize.width <= 1100
                        ? 14
                        : 15,
                  }}
                >
                  <Tooltip
                    title={<span>{item?.projectName}</span>}
                    placement="right"
                    followCursor
                  >
                    <Typography
                      noWrap={true}
                      sx={{
                        cursor: "default",
                        width: 0.1,
                        minWidth: 0.1,
                        textAlign: "center",
                        px: 1,
                        fontWeight: "bold",
                        color: theme.palette.custom.primary,
                        fontSize:
                          windowSize.width <= 720
                            ? 12
                            : windowSize.width <= 1100
                            ? 14
                            : 15,
                      }}
                    >
                      {item?.projectName}
                    </Typography>
                  </Tooltip>
                  <Box
                    sx={{
                      width: 0.45,
                      minWidth: "40%",
                      maxWidth: "45%",
                    }}
                  >
                    <Tooltip
                      title={
                        <span style={{ whiteSpace: "pre-line" }}>
                          {item?.detail}
                        </span>
                      }
                      placement="right"
                      followCursor
                    >
                      <Typography
                        className={classes.multiLineEllipsis}
                        whiteSpace="pre-line"
                        sx={{
                          cursor: "default",
                          fontSize:
                            windowSize.width <= 720
                              ? 12
                              : windowSize.width <= 1100
                              ? 14
                              : 15,
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtmlContent(item?.detail),
                          }}
                        ></span>
                      </Typography>
                    </Tooltip>
                  </Box>
                  {windowSize.width > 720 ? (
                    <Typography
                      sx={{
                        minWidth:
                          windowSize.width > 1100
                            ? 90
                            : windowSize.width > 720
                            ? 80
                            : 65,
                        maxWidth:
                          windowSize.width > 1100
                            ? 90
                            : windowSize.width > 720
                            ? 80
                            : 65,
                        color:
                          item.status === "In process"
                            ? STATUS_COLORS.inProcess
                            : item.status === "Pending"
                            ? STATUS_COLORS.pending
                            : STATUS_COLORS.done,
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize:
                          windowSize.width <= 720
                            ? 12
                            : windowSize.width <= 1100
                            ? 14
                            : 15,
                      }}
                    >
                      {item.status}
                    </Typography>
                  ) : (
                    <></>
                  )}

                  <Box sx={{ width: 0.1, textAlign: "center" }}>{`${
                    item?.startTime === null ? "--:-- " : item?.startTime
                  }-${
                    item?.endTime === null ? " --:-- " : item?.endTime
                  }`}</Box>
                  <Box
                    sx={{ width: 0.1, pl: "5px", textAlign: "center" }}
                  >{`${item?.hours}hrs ${item?.minutes}mins`}</Box>
                  {windowSize.width <= 1280 ? (
                    <>
                      <PopoverAction
                        onDelete={() => {
                          handleOpenDeleteModal(item);
                        }}
                        onEdit={() => {
                          handleOpenEditModal(item);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Stack
                        spacing={1}
                        direction={"row"}
                        sx={{ px: "10px", width: "fit-content" }}
                      >
                        <Tooltip
                          title={
                            item?.isSynced ? (
                              <span>Email was sent</span>
                            ) : (
                              <span>Edit</span>
                            )
                          }
                          placement="right"
                          followCursor
                        >
                          <EditIcon
                            sx={{
                              color: theme.palette.custom.edit,
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!item?.isSynced) {
                                handleOpenEditModal(item);
                              } else {
                                handleOpenEditModal(item);
                              }
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            item?.isSynced ? (
                              <span>Email was sent</span>
                            ) : (
                              <span>Delete</span>
                            )
                          }
                          placement="right"
                          followCursor
                        >
                          <DeleteIcon
                            sx={{
                              color: item?.isSynced
                                ? theme.palette.custom.grey
                                : theme.palette.custom.delete,
                            }}
                            style={{
                              cursor: item?.isSynced ? "default" : "pointer",
                            }}
                            onClick={() => {
                              if (!item?.isSynced) handleOpenDeleteModal(item);
                            }}
                          />
                        </Tooltip>
                      </Stack>
                    </>
                  )}
                </Stack>
                <Divider sx={{ width: 0.95, mx: "auto" }} />
              </div>
            );
          })
        )}
      </Collapse>
    </>
  );
};

export default WFOActivityCollapseCard;
