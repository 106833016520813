import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";

import {
  Box,
  Stack,
  Divider,
  useTheme,
  Typography,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  IconButton,
} from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import useWindowSize from "../../hooks/useWindowSize";
import { sanitizeHtmlContent } from "../../utils/stringHelper";
import moment from "moment";
import WFOActivityCollapseCard from "./WFOActivityCollapseCard";
import { statusColors } from "../../utils/color";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { fetchActivities } from "../../fetch/fecthActivity";

const STATUS_COLORS = statusColors;

const calculateTotalWorkHours = (wfo) => {
  let wfoHours = 0,
    wfoMinutes = 0;
  let resultHours = 0,
    resultMinutes = 0;

  if (wfo && wfo.hours > 0) {
    let decimalStrs = parseFloat(wfo?.hours).toFixed(2).toString().split(".");

    if (decimalStrs && decimalStrs.length == 2) {
      wfoHours = Number.parseInt(decimalStrs[0]);
      wfoMinutes = Number.parseInt(decimalStrs[1]);
    } else if (decimalStrs && decimalStrs.length == 1) {
      wfoHours = Number.parseInt(decimalStrs[0]);
    }

    resultHours = wfoHours;
    resultMinutes = wfoMinutes;

    return `${resultHours} hrs ${resultMinutes} mins`;
  } else {
    return "-- hrs -- mins";
  }
};

const WFOTotalActivityHours = ({ wfo }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
    >
      <Box sx={{ textAlign: "center" }}>{`
            ${
              wfo?.checkIn === null
                ? "--:-- "
                : moment(wfo?.checkIn).format("HH:mm")
            }
            -
            ${
              wfo?.checkOut === null
                ? " --:-- "
                : moment(wfo?.checkOut).format("HH:mm")
            }`}</Box>
      <Box sx={{ textAlign: "center" }}>{calculateTotalWorkHours(wfo)}</Box>
    </Box>
  );
};

const WFOActivityTitle = () =>
  // { projectName = "" }
  {
    const windowSize = useWindowSize();
    const theme = useTheme();

    return (
      <Typography
        noWrap={true}
        sx={{
          cursor: "default",
          width: 0.1,
          minWidth: 0.1,
          textAlign: "center",
          fontWeight: "bold",
          color: theme.palette.custom.primary,
          fontSize:
            windowSize.width <= 720 ? 12 : windowSize.width <= 1100 ? 14 : 15,
        }}
      >
        WFO
      </Typography>
    );
  };

const WFOActivityMobileHeader = ({
  wfo,
  handleOpenWFOEditModal,
  isCollapse,
  setIsCollapse,
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        pr: 2,
        mb: 1,
      }}
    >
      <IconButton
        sx={{ ml: "10px" }}
        onClick={() => setIsCollapse(!isCollapse)}
      >
        {isCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
      <WFOActivityTitle projectName={wfo?.projectName} />

      <WFOTotalActivityHours wfo={wfo} />

      <WFOActivityMenus onClickEditMenu={() => handleOpenWFOEditModal(wfo)} />
    </Stack>
  );
};

const WFOActivityMenus = ({ onClickEditMenu }) => {
  const menuRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleClickEdit = () => {
    setOpenMenu(false);
    onClickEditMenu();
  };

  return (
    <>
      <MoreVertOutlinedIcon
        ref={menuRef}
        onClick={() => {
          setOpenMenu((v) => !v);
        }}
        sx={{
          cursor: "pointer",
          color: "black",
          mr: 2,
        }}
      />
      <Popper
        open={openMenu}
        anchorEl={menuRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal={true}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                <MenuList
                  autoFocusItem={openMenu}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <MenuItem
                    //disabled={wfo?.isSentEmail}
                    onClick={handleClickEdit}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    disabled={true}
                    onClick={() => {
                      setOpenMenu(false);
                    }}
                  >
                    Duplicate
                  </MenuItem>
                  <MenuItem
                    disabled={true}
                    onClick={() => {
                      setOpenMenu(false);
                    }}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default function WFOActivityCard({
  wfoItems,
  itemDate,
  statusColors,
  handleOpenWFOEditModal,
  handleOpenEditModal,
  handleOpenDeleteModal,
}) {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const [wfo, setWFO] = useState(null);
  const [data, setData] = useState(null);

  const [openCollapse, setOpenCollapse] = useState(false);

  const isDesktop = windowSize.width > 600;
  const refCount = useRef(-1);
  

  const getData = useCallback(async (Item) => {
    let response = null;
    if (Item !== null) {
      response = await fetchActivities(moment(Item).format("YYYY-MM-DD"));
    }
    if (response !== null) {
      let filtedData = response.filter((e) => !e.workFromHome);
      setData(filtedData);
      if (refCount.current !== -1) {
        if (refCount.current !== filtedData.length) {
          setOpenCollapse(true);
          refCount.current = filtedData.length;
        }
      } else refCount.current = filtedData.length;
    }
  }, []);

  useEffect(() => {
    if (wfoItems) {
      const filterDate = moment(itemDate.date).format("YYYY-MM-DD");
      const matchItem = wfoItems.filter(
        (x) => moment(x.checkIn).format("YYYY-MM-DD") == filterDate
      );
      setWFO(matchItem && matchItem.length > 0 ? matchItem[0] : null);
      getData(matchItem && matchItem.length > 0 ? matchItem[0].checkIn : null);
    }
  }, [itemDate, wfoItems]);

  return (
    <>
      {wfo ? (
        <>
          <Stack
            direction={isDesktop ? "row" : "column"}
            sx={{
              mx: "auto",
              width: 0.95,
              p: "20px 0px",
              alignItems: isDesktop ? "center" : "left",
              borderRadius: "5px",
              borderLeft: windowSize.width <= 720 ? 10 : 0,
              borderColor:
                windowSize.width <= 720
                  ? wfo?.status === "In process"
                    ? statusColors.inProcess
                    : wfo?.status === "Pending"
                    ? statusColors.pending
                    : statusColors.done
                  : theme.palette.custom.white,
              backgroundColor: theme.palette.custom.white,
              whiteSpace: "nowrap",
              justifyContent: "space-between",
              fontSize:
                windowSize.width <= 720
                  ? 12
                  : windowSize.width <= 1100
                  ? 14
                  : 15,
            }}
          >
            {isDesktop ? (
              <>
                <IconButton
                  sx={{ ml: "10px" }}
                  onClick={() => {
                    setOpenCollapse(!openCollapse);
                  }}
                >
                  {openCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <WFOActivityTitle projectName={wfo?.projectName} />
              </>
            ) : (
              <>
                <WFOActivityMobileHeader
                  wfo={wfo}
                  handleOpenWFOEditModal={handleOpenWFOEditModal}
                  isCollapse={openCollapse}
                  setIsCollapse={() => {
                    setOpenCollapse(!openCollapse);
                  }}
                />
              </>
            )}

            <Box
              sx={{
                width: isDesktop ? 0.45 : "auto",
                px: isDesktop ? "unset" : 2,
                // minWidth: "45%",
                // maxWidth: "45%",
              }}
            >
              <Typography
                whiteSpace="pre-line"
                sx={{
                  cursor: "default",
                  maxHeight: 360,
                  overflow: "auto",
                  fontSize:
                    windowSize.width <= 720
                      ? 12
                      : windowSize.width <= 1100
                      ? 14
                      : 15,
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtmlContent(wfo?.inOutDetail),
                  }}
                ></span>
              </Typography>
            </Box>
            {windowSize.width > 720 ? (
              <Typography
                sx={{
                  minWidth:
                    windowSize.width > 1100
                      ? 90
                      : windowSize.width > 720
                      ? 80
                      : 65,
                  maxWidth:
                    windowSize.width > 1100
                      ? 90
                      : windowSize.width > 720
                      ? 80
                      : 65,
                  color:
                    wfo?.status === "In process"
                      ? statusColors.inProcess
                      : wfo?.status === "Pending"
                      ? statusColors.pending
                      : statusColors.done,
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize:
                    windowSize.width <= 720
                      ? 12
                      : windowSize.width <= 1100
                      ? 14
                      : 15,
                }}
              ></Typography>
            ) : (
              <></>
            )}

            {isDesktop && <WFOTotalActivityHours wfo={wfo} />}

            {/* {windowSize.width > 1280 ? (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ px: 4, width: "fit-content" }}
                >
                  <Tooltip
                    title={
                      wfo?.isSentEmail ? (
                        <span>Email was sent</span>
                      ) : (
                        <span>Edit</span>
                      )
                    }
                    placement="right"
                    followCursor
                  >
                    <EditIcon
                      sx={{
                        color: theme.palette.custom.edit,
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleOpenWFOEditModal(wfo);
                      }}
                    />
                  </Tooltip>
                </Stack>
              </>
            ) : (
              isDesktop && (
                <WFOActivityMenus
                  onClickEditMenu={() => handleOpenWFOEditModal(wfo)}
                />
              )
            )} */}
          </Stack>
          <Divider sx={{ width: 0.95, mx: "auto" }} />
          <WFOActivityCollapseCard
            STATUS_COLORS={STATUS_COLORS}
            task={data}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenEditModal={handleOpenEditModal}
            openCollapse={openCollapse}
            setCollapes={(e) => {
              setOpenCollapse(e);
            }}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}
