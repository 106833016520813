import React, { useState, useEffect } from "react";

import { Box, Stack, useTheme } from "@mui/material";

import {
  ScheduleFilter,
  ScheduleContent,
} from "../components/ScheduleComponents";

import { useQuery } from "react-query";
import { fetchEmployeeTeam } from "../fetch/fetchTeam";
import { endOfWeek, startOfWeek } from "date-fns";

export default function Schedule() {
  let curr = new Date();
  let start = startOfWeek(curr);
  let end = endOfWeek(curr);

  const theme = useTheme();

  const projects = [];

  const [dateSelected, setDateSelected] = useState(new Date());
  const [firstday, setFirstday] = useState(start);
  const [lastday, setLastday] = useState(end);
  const [mainProjectSelected, setMainProjectSelected] = useState([]);
  curr = new Date();
  firstday.setHours(0, 0, 0, 0);
  lastday.setHours(0, 0, 0, 0);

  const { data: projectData, status: projectStatus } = useQuery(
    ["projectToken"],
    () => fetchEmployeeTeam()
  );

  useEffect(() => {
    curr = new Date(dateSelected);
    start = startOfWeek(curr);
    end = endOfWeek(curr);
    setFirstday(start);
    setLastday(end);
    curr = new Date(dateSelected);
    firstday.setHours(0, 0, 0, 0);
    lastday.setHours(0, 0, 0, 0);
  }, [dateSelected]);

  const dateSelectedChange = (value) => {
    setDateSelected(value);
    let date = new Date(value);
    let firstDateOfWeek = new Date(value);
    let lastDateOfWeek = new Date(value);
    firstDateOfWeek.setHours(-24 * date.getDay());
    lastDateOfWeek.setHours(+24 * (7 - 1 - date.getDay()));
  };

  if (projectStatus === "success") {
    projectData.data.projectList.map((p) => {
      projects.push(p.projectName);
    });
    projects.push("WFO");
  }

  return (
    <Stack
      direction="column"
      sx={{
        width: 1,
        height: 1,
        minHeight: 600,
        mx: "auto",
        mb: 4,
        backgroundColor: theme.palette.custom.white,
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          height: 80,
          boxShadow: 2,
          p: "10px 10px",
          alignItems: "center",
          display: "flex",
          minWidth: "fit-content",
        }}
      >
        <ScheduleFilter
          projects={projects}
          dateSelected={dateSelected}
          dateSelectedChange={dateSelectedChange}
          setDateSelected={setDateSelected}
          setMainProjectSelected={setMainProjectSelected}
        />
      </Box>

      <ScheduleContent
        dateSelected={dateSelected}
        firstday={firstday}
        lastday={lastday}
        mainProjectSelected={mainProjectSelected}
      />
    </Stack>
  );
}
