import React, { useEffect } from "react";
import { Stack, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

export default function TeamActivityFilter({
  startDate,
  endDate,
  selectedProjectId,
  projects,
  setStartDate,
  setEndDate,
  setSelectedProjectId,
  onSearchClick,
  fetchEmployeeTeamData
}) {

  useEffect(() => {
    fetchEmployeeTeamData();
  }, []);

  const handleProjectChange = (event) => {
    setSelectedProjectId(event.target.value);
  };

  return (
    <>
      <Stack
        sx={{
          mt: 2,
        }}
        justifyContent="center"
        alignItems="center"
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 2, sm: 2, md: 4 }}
      >
        <Box>
          <FormControl sx={{ minWidth: 240 }} size="small">
            <InputLabel id="demo-simple-select-label">Project</InputLabel>
            <Select
              value={selectedProjectId}
              label="Project"
              onChange={handleProjectChange}
            >
              {projects.map((project) => (
                <MenuItem key={project.projectId} value={project.projectId}>
                  {project.projectName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={startDate}
              inputFormat={"dd/MM/yyyy"}
              maxDate={endDate}
              onChange={(newValue) => {
                newValue.setHours(0, 0, 0);
                if (newValue <= endDate) setStartDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              value={endDate}
              inputFormat={"dd/MM/yyyy"}
              maxDate={new Date()}
              minDate={startDate}
              onChange={(newValue) => {
                newValue.setHours(23, 59, 59);
                if (newValue >= startDate) setEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </Box>
        <Box>
          <Button variant="contained" endIcon={<SearchOutlinedIcon />} onClick={onSearchClick}>
            Search
          </Button>
        </Box>
      </Stack>
    </>
  );
}
