import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

const PopoverAction = ({ onDelete, onEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete();
  };

  const handleEdit = () => {
    onEdit();
  };

  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={(event) => {
          handleClick(event);
        }}
      >
        <MoreVertOutlinedIcon
          sx={{
            cursor: "pointer",
            color: "black",
            mr: 2,
          }}
        />
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <MenuItem
                    disabled={false}
                    onClick={() => {
                      handleEdit();
                      handleClose();
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    disabled={false}
                    onClick={() => {
                      handleDelete();
                      handleClose();
                    }}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default PopoverAction;
