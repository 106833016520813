import { axiosHelper } from "../utils/axiosHelper";

export async function fetchTimeAttendancesByCurrentEmployee(startDate, endDate) {
    return await axiosHelper.get("/api/Attendance/SelectAttendancesByCurrentEmployee", {
        date1: startDate,
        date2: endDate,
    });
}

export async function updateWFOActivity(newWFO) {
    return await axiosHelper.post("/api/Attendance/EditWorkDetails", newWFO);
}