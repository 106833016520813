import React from "react";
import { Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Announcement } from "../components/AnnouncementComponents";
import useWindowSize from "../hooks/useWindowSize";

export default function Announcements() {
  const theme = useTheme();
  const windowSize = useWindowSize();

  return (
    <Box
      sx={{
        width: 1,
        margin: 0,
        height: "auto",
        display: "flex",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.custom.white,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: windowSize.width >= 1100 ? "center" : "start",
          alignItems: "center",
          minHeight: "fit-content",
          height: 1,
          py: 3,
          pt: windowSize.width >= 1100 ? "15vh" : "0vh",
          pb: "15vh"
        }}
      >
        {windowSize.width < 1100 && (
          <Typography
            align="center"
            sx={{
              color: theme.palette.custom.defaultTextColor,
              fontWeight: 400,
              fontSize: 25,
              mt: "6rem",
              mb: "2rem",
            }}
          >
            Welcome to Mycos Task Tracker
          </Typography>
        )}
        <Announcement />
      </Box>
    </Box>
  );
}
