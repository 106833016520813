import { CircularProgress, Typography } from "@mui/material";

import { useQuery } from "react-query";

import { fetchTeamMember } from "../../../fetch/fetchTeam";

import useWindowSize from "../../../hooks/useWindowSize";

export default function TeamMember({ projectId }) {
  const windowSize = useWindowSize();

  const { data, status } = useQuery(`memberToken${projectId}`, () =>
    fetchTeamMember(projectId)
  );
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  return (
    <>
      {status === "success" ? (
        <>
          {data.data.map((Member) => (
            <Typography
              key={Member.employeeId}
              sx={{
                cursor: "default",
                fontSize:
                  windowSize.width <= 720
                    ? 12
                    : windowSize.width <= 1100
                    ? 14
                    : 16,
              }}
            >
              {Capitalize(Member.firstName) + " " + Capitalize(Member.lastName)}
            </Typography>
          ))}{" "}
        </>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
    </>
  );
}
