import React from "react";

import {
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import toast from "react-hot-toast";

import { useMutation, useQueryClient } from "react-query";
import {
  fetchDeleteActivity,
  fetchDeleteActivityWFO,
} from "../../fetch/fecthActivity";

export default function DeleteModal({
  openDeleteModal,
  handleCloseDeleteModal,
  task,
  handleRefreshData,
}) {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(fetchDeleteActivity, {
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries([
        "activities",
        new Date(task.date).toDateString(),
      ]);
      queryClient.invalidateQueries([
        "totalWorkHours",
        new Date().toDateString(),
      ]);
    },
    onSuccess: () => {
      toast.success("Activity is deleted successfully.");
      handleRefreshData();
      handleCloseDeleteModal();
    },
  });

  const { mutate: mutateWFO } = useMutation(fetchDeleteActivityWFO, {
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries([
        "activities",
        new Date(task.date).toDateString(),
      ]);
      queryClient.invalidateQueries([
        "totalWorkHours",
        new Date().toDateString(),
      ]);
    },
    onSuccess: () => {
      toast.success("Activity is deleted successfully.");
      handleRefreshData();
      handleCloseDeleteModal();
    },
  });

  const deleteTask = () => {
    if (task.workFromHome) {
      mutate(task.activityId);
    } else {
      mutateWFO(task.activityId);
    }
  };
  return (
    <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
      <DialogTitle
        color={theme.palette.custom.defaultTextColor}
        fontSize={20}
        fontWeight={600}
      >
        {"Delete comfirmation"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure to delete this activity?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDeleteModal}
          variant="contained"
          color="inherit"
        >
          No
        </Button>
        <Button
          onClick={() => deleteTask()}
          variant="contained"
          color="confirmBtn"
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.custom.lightPrimary,
            },
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
