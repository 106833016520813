import React from "react";

import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { endOfWeek, isSameDay, isWithinInterval, startOfWeek } from "date-fns";

const CUSTOM_PICKER_SDAY = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

export default function CustomDay({ label, dateSelected, onChange, width }) {
  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!dateSelected) {
      return <PickersDay {...pickersDayProps} />;
    }
    const start = startOfWeek(dateSelected);
    const end = endOfWeek(dateSelected);
    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);
    return (
      <CUSTOM_PICKER_SDAY
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={dateSelected}
        onChange={onChange}
        renderDay={renderWeekPickerDay}
        renderInput={(params) => (
          <TextField {...params} size="small" sx={{ width: { width } }} />
        )}
        inputFormat="'Week of' MMM d"
        disableMaskedInput
      />
    </LocalizationProvider>
  );
}
