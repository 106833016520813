import { FormControl, Select, MenuItem } from "@mui/material";

export default function StatusSelector({
  taskStatus,
  setTaskStatus,
  statusList,
}) {
  return (
    <FormControl sx={{ width: 1, minWidth: 170 }} size="small">
      <Select
        value={taskStatus}
        onChange={(e) => {
          setTaskStatus(e.target.value);
        }}
      >
        {statusList.map((s) => (
          <MenuItem key={s} value={s}>
            {s}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
