import React from "react";

import { Box, Typography, CircularProgress, useTheme } from "@mui/material";
import { lightGreen, red } from "@mui/material/colors";

export default function DateAndWorkHoursCard({
  date,
  dayDetails,
  isHoliday,
  isSunSat,
  loadStatus,
}) {
  const changeDigitMinutes = (minutes) => {
    return minutes < 10 ? "0" + minutes : minutes;
  };

  const theme = useTheme();

  const showDiffTime = (hours, minutes) => {
    if (isHoliday || isSunSat) {
      return (
        "(+" +
        hours +
        ":" +
        (minutes == 0 ? "00" + ")" : changeDigitMinutes(minutes) + ")")
      );
    }
    return hours >= 8
      ? "(+" +
          Math.abs(8 - hours) +
          ":" +
          (minutes == 0 ? "00" + ")" : changeDigitMinutes(minutes) + ")")
      : "(-" +
          (8 - hours - (1 && minutes != 0)) +
          ":" +
          (minutes == 0 ? "00" + ")" : changeDigitMinutes(60 - minutes) + ")");
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        color: theme.palette.custom.white,
        backgroundColor: theme.palette.custom.primary,
        px: "10px",
      }}
    >
      {loadStatus ? (
        <Box sx={{ my: "auto" }}>
          <Typography
            align="center"
            sx={{
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {date}
          </Typography>
          <Typography
            align="center"
            sx={{
              fontSize: 14,
              fontWeight: 500,
              ...(dayDetails?.hours >= 8 || isHoliday || isSunSat
                ? { color: lightGreen[500] }
                : { color: red[300] }),
            }}
          >
            {dayDetails?.hours +
              ":" +
              changeDigitMinutes(dayDetails?.minutes) +
              " " +
              showDiffTime(dayDetails?.hours, dayDetails?.minutes)}
          </Typography>
        </Box>
      ) : (
        <CircularProgress sx={{ m: "auto" }} />
      )}
    </Box>
  );
}
