import React from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

export default function StatusWorkHoursBadge() {
  return (
    <Stack
      sx={{ mt: 3 }}
      justifyContent={{ xs: "center", sm: "right" }}
      alignItems={{ xs: "center", sm: "right" }}
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 2, sm: 2, md: 4 }}
    >
      <Box p={1}>
        <Grid container spacing={1} direction="row">
          <Grid item>
            <Typography>
              <CircleIcon
                fontSize="small"
                style={{
                  color: "#6EC1B2",
                  marginRight: 5,
                  top: 4,
                  position: "relative",
                }}
              />
              Work time
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <CircleIcon
                fontSize="small"
                style={{
                  color: "#FFAB00",
                  marginRight: 5,
                  top: 4,
                  position: "relative",
                }}
              />
              Leave
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
