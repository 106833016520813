const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const getDaysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};

const getFirstDayOfMonth = (month, year) => {
  return new Date(year, month, 1).getDay();
};

const getLastDayOfMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDay();
};

const getMonthName = (month) => {
  return months[month];
};

const getDayName = (day) => {
  return days[day];
};

const getDaysInMonthArray = (month, year) => {
  const daysInMonth = getDaysInMonth(month, year);
  const firstDayOfMonth = getFirstDayOfMonth(month, year);
  const lastDayOfMonth = getLastDayOfMonth(month, year);
  const daysInMonthArray = [];

  for (let i = 0; i < firstDayOfMonth; i++) {
    daysInMonthArray.push(null);
  }

  for (let i = 1; i <= daysInMonth; i++) {
    daysInMonthArray.push(i);
  }

  for (let i = 1; i <= 6 - lastDayOfMonth; i++) {
    daysInMonthArray.push(null);
  }

  return daysInMonthArray;
};

const getMonthArray = (month, year) => {
  const daysInMonthArray = getDaysInMonthArray(month, year);
  const monthArray = [];

  for (let i = 0; i < daysInMonthArray.length; i += 7) {
    monthArray.push(daysInMonthArray.slice(i, i + 7));
  }

  return monthArray;
};

const getMonthArrayWithDays = (month, year) => {
  const monthArray = getMonthArray(month, year);
  const monthArrayWithDays = [];

  for (let i = 0; i < monthArray.length; i++) {
    const week = monthArray[i];
    const weekWithDays = [];

    for (let j = 0; j < week.length; j++) {
      const day = week[j];
      const dayWithDay = {
        day: day,
        dayName: getDayName(j),
      };

      weekWithDays.push(dayWithDay);
    }

    monthArrayWithDays.push(weekWithDays);
  }

  return monthArrayWithDays;
};

const getMonthArrayWithDaysAndMonths = (month, year) => {
  const monthArrayWithDays = getMonthArrayWithDays(month, year);
  const monthArrayWithDaysAndMonths = [];

  for (let i = 0; i < monthArrayWithDays.length; i++) {
    const week = monthArrayWithDays[i];
    const weekWithDaysAndMonths = [];

    for (let j = 0; j < week.length; j++) {
      const day = week[j];
      const dayWithDayAndMonth = {
        day: day,
        dayName: getDayName(j),
        monthName: getMonthName(month),
        month: month,
        year: year,
      };

      weekWithDaysAndMonths.push(dayWithDayAndMonth);
    }

    monthArrayWithDaysAndMonths.push(weekWithDaysAndMonths);
  }

  return monthArrayWithDaysAndMonths;
};

export {
  getMonthArrayWithDaysAndMonths,
  getMonthArrayWithDays,
  getMonthArray,
  getMonthName,
  getDayName,
  getDaysInMonthArray,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getDaysInMonth,
};
