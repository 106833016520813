import React from "react";
import { useTheme, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function WorkDateInput({
  inputModeType,
  currentSelectedDate,
  handleWorkDateChange,
}) {
  const theme = useTheme();
  return (inputModeType === 0) && (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        maxDate={Date.now()}
        inputFormat="dd/MM/yyyy"
        inputVariant="outlined"
        value={currentSelectedDate}
        onChange={handleWorkDateChange}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              width: 200,
              color: theme.palette.custom.white,
              border: theme.palette.custom.white,
              input: { color: theme.palette.custom.white },
              svg: { color: theme.palette.custom.white },
            }}
            inputlabelprops={{
              style: {
                color: theme.palette.custom.white,
                borderColor: theme.palette.custom.white,
              },
            }}
            size="small"
            variant="outlined"
            label="Work date"
            color="inputColor"
            type="date"
          />
        )}
      />
    </LocalizationProvider>
  );
}