import React, { useState } from "react";

import { useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

import TableRowDetail from "./TableRow";

export default function WorkHoursTable({ setPage, page, workHoursDetail, setSelectedWork, handleOpenActivityDetailsModal }) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [changePage, setChangePage] = useState(false);
  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setChangePage(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ mt: 2 }}>
        <TableContainer>
          <Table aria-label="employee work hours table">
            <colgroup>
              <col style={{ width: "1%" }} />
              <col style={{ width: "1%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "8%" }} />
              <col style={{ width: "8%" }} />
              <col style={{ width: "8%" }} />
              <col style={{ width: "8%" }} />
              <col style={{ width: "33%" }} />
              <col style={{ width: "8%" }} />
            </colgroup>
            <TableHead>
              <TableRow style={{ backgroundColor: theme.palette.primary.main }}>
                <TableCell />
                <TableCell
                  style={{ color: theme.palette.primary.contrastText }}
                  align="center"
                >
                  #
                </TableCell>
                <TableCell
                  style={{ color: theme.palette.primary.contrastText }}
                  align="center"
                >
                  Team
                </TableCell>
                <TableCell
                  style={{ color: theme.palette.primary.contrastText }}
                  align="center"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ color: theme.palette.primary.contrastText }}
                  align="center"
                >
                  NickName
                </TableCell>
                <TableCell
                  style={{ color: theme.palette.primary.contrastText }}
                  align="center"
                >
                  Standard Hours
                </TableCell>
                <TableCell
                  style={{ color: theme.palette.primary.contrastText }}
                  align="center"
                >
                  Work Hours
                </TableCell>
                <TableCell
                  style={{ color: theme.palette.primary.contrastText }}
                  align="center"
                >
                  Leave Hours
                </TableCell>
                <TableCell
                  style={{ color: theme.palette.primary.contrastText }}
                  align="center"
                >
                  Total Work Hours
                </TableCell>
                <TableCell
                  style={{ color: theme.palette.primary.contrastText }}
                  align="center"
                >
                  Status
                </TableCell>
                <TableCell
                  style={{ color: theme.palette.primary.contrastText }}
                  align="center"
                >
                  OverTime Hours
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workHoursDetail
                .map((wh, key) => {
                  var sumStandardH = wh.workHistory.reduce(
                    (w, k) => w + k.standardHours,
                    0
                  );
                  var sumWorkH = wh.workHours;
                  var sumLeaveH = wh.leaveHours;
                  var sumTotalH = wh.totalWorks;

                  return (
                    <TableRowDetail
                      wh={wh}
                      no={key}
                      key={key}
                      sumStandardH={sumStandardH}
                      sumWorkH={sumWorkH}
                      sumLeaveH={sumLeaveH}
                      sumTotalH={sumTotalH}
                      changePage={changePage}
                      setChangePage={setChangePage}
                      setSelectedWork={setSelectedWork}
                      handleOpenActivityDetailsModal={handleOpenActivityDetailsModal}
                    />
                  );
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={workHoursDetail.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
