import React from "react";

import { Button, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { getMonthName } from "../../utils/calendarHelper";

export default function CalendarHeader({ date, setDate }) {
  const theme = useTheme();
  const ColorButton = styled(Button)(() => ({
    color: theme.palette.custom.white,
    backgroundColor: theme.palette.custom.primary,
    "&:hover": {
      backgroundColor: theme.palette.custom.lightPrimary,
    },
  }));

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          p: "1.7rem 0rem",
        }}
      >
        <Box></Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              color: theme.palette.custom.defaultTextColor,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ArrowBackIosIcon
              onClick={() =>
                setDate(new Date(date.getFullYear(), date.getMonth() - 1))
              }
              sx={{
                cursor: "pointer",
              }}
            />
            <Typography
              sx={{
                fontWeight: "bold",
                width: 150,
                textAlign: "center",
              }}
            >
              {getMonthName(date.getMonth()) + ", " + date.getFullYear()}
            </Typography>
            <ArrowForwardIosIcon
              onClick={() =>
                setDate(new Date(date.getFullYear(), date.getMonth() + 1))
              }
              sx={{
                cursor: "pointer",
              }}
            />
          </Box>
          <ColorButton variant="contained" onClick={() => setDate(new Date())}>
            Today
          </ColorButton>
        </Box>
      </Box>
    </>
  );
}
