import { axiosHelper } from "../utils/axiosHelper";

export async function fetchHoliday(startDate, endDate) {
  return await axiosHelper.get("/api/attendance/holidayWeek", {
    sd: startDate,
    ed: endDate,
  });
}

export async function fetchEmployeeLeave(startDate, endDate) {
  return await axiosHelper.get("/api/Employee/leaveWeek", {
    sd: startDate,
    ed: endDate,
  });
}
