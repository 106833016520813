import React, { useState } from "react";

import { Box, Stack, useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

import { ProjectDetail, DisplayChart } from "../components/DashboardComponents";

import { useQuery } from "react-query";
import { fetchEmployeeTeam } from "../fetch/fetchTeam";
import useWindowSize from "../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
export default function Dashboard() {
  const theme = useTheme();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const [projectDetail, setProjectDetail] = useState();

  const { data, status } = useQuery(
    "employeeTeamToken",
    () => fetchEmployeeTeam(),
    {
      onError: () => {
        navigate("/error500");
      },
    }
  );
  return (
    <>
      <Stack direction={windowSize.width > 1440 ? "row" : "column"} spacing={2}>
        <Box
          sx={{
            backgroundColor: theme.palette.custom.white,
            width: windowSize.width > 1440 ? 0.6 : 0.9,
            borderRadius: "5px",
          }}
        >
          {status === "success" ? (
            <DisplayChart
              Data={data.data}
              setProjectDetail={setProjectDetail}
            />
          ) : (
            <>
              <LinearProgress />
            </>
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.custom.white,
            width: windowSize.width > 1440 ? 0.4 : 0.9,
            height: 1,
            borderRadius: "5px",
          }}
        >
          {status === "success" ? (
            <>
              <ProjectDetail projectDetail={projectDetail} />
            </>
          ) : (
            <>
              <LinearProgress />
            </>
          )}
        </Box>
      </Stack>
    </>
  );
}
